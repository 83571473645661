/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';

export class CalenderPage extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		return (
			<section className="bg-white border-b ">
				<div className="container mx-auto flex flex-wrap pt-4 pb-12" id="myP">
					<h1 className="w-full my-2 text-2xl font-bold leading-tight text-center text-gray-800">Calendar</h1>
					<div className="w-full mb-4">
						<div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t" />
					</div>
					<table className="text-left w-full">
						<thead className="bg-black flex text-white w-full">
							<tr className="flex w-full mb-4">
								<th className="p-4 w-1/4">Sr.No.</th>
								<th className="p-4 w-1/4">Court</th>
								<th className="p-4 w-1/4">Year</th>
								<th className="p-4 w-1/4">View</th>
							</tr>
						</thead>
						{/* Remove the nasty inline CSS fixed height on production and replace it with a CSS class — this is just for demonstration purposes! */}
						<tbody className="flex flex-col items-center justify-between overflow-y-scroll w-full">
							{Calender.map((ag, i) => {
								return (
									<tr className="flex w-full mb-4  items-center" key={i}>
										<td className="p-4 w-1/4">{ag.sr}</td>
										<td className="p-4 w-1/4">{ag.court}</td>
										<td className="p-4 w-1/4">{ag.year}</td>
										<td className="p-4 w-1/4">
											<a
												href={ag.src}
												className="hover:underline "
												target="_blank"
												style={{ color: 'blue' }}
											>
												View
											</a>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			</section>
		);
	}
}

export default CalenderPage;

const Calender = [
	{
		sr: '1',
		court: 'High Court',
		year: '2019',
		src: 'http://highcourt.cg.gov.in/other/cal2019/hc_2019.pdf'
	},
	{
		sr: '2',
		court: 'High Court',
		year: '2018',
		src: 'http://highcourt.cg.gov.in/other/cal2018/CALENDER-2018COLOUR.pdf'
	},
	{
		sr: '3',
		court: 'High Court',
		year: '2017',
		src: 'http://highcourt.cg.gov.in/other/cal2017/hccal2017.jpg'
	},
	{
		sr: '4',
		court: 'High Court',
		year: '2016',
		src: 'http://highcourt.cg.gov.in/other/cal2016/hccal2016.jpg'
	},
	{
		sr: '5',
		court: 'High Court',
		year: '2015',
		src: 'http://highcourt.cg.gov.in/other/cal2015/hccal2015.jpg'
	},
	{
		sr: '6',
		court: 'High Court',
		year: '2014',
		src: 'http://highcourt.cg.gov.in/other/cal2014/hccal2014.jpg'
	},
	{
		sr: '7',
		court: 'High Court',
		year: '2013',
		src: 'http://highcourt.cg.gov.in/other/calender2013.pdf'
	},
	{
		sr: '8',
		court: 'High Court',
		year: '2012',
		src: 'http://highcourt.cg.gov.in/other/calender2012.jpg'
	},
	{
		sr: '9',
		court: 'High Court',
		year: '2011',
		src: 'http://highcourt.cg.gov.in/other/HC_Calendar_2011.jpg'
	},
	{
		sr: '10',
		court: 'High Court',
		year: '2010',
		src: 'http://highcourt.cg.gov.in/other/HC_Calendar_2010.jpg'
	},
	{
		sr: '11',
		court: 'Subordinate Court',
		year: '2019',
		src: 'http://highcourt.cg.gov.in/other/cal2019/dc_2019.pdf'
	},
	{
		sr: '12',
		court: 'Subordinate Court',
		year: '2018',
		src: 'http://highcourt.cg.gov.in/other/cal2018/dc2018.pdf'
	},
	{
		sr: '13',
		court: 'Subordinate Court',
		year: '2017',
		src: 'http://highcourt.cg.gov.in/other/cal2017/dc2017.pdf'
	},
	{
		sr: '14',
		court: 'Subordinate Court',
		year: '2016',
		src: 'http://highcourt.cg.gov.in/other/cal2016/dc2016.jpg'
	},
	{
		sr: '15',
		court: 'Subordinate Court',
		year: '2015',
		src: 'http://highcourt.cg.gov.in/other/cal2015/dc2015.jpg'
	},
	{
		sr: '16',
		court: 'Subordinate Court',
		year: '2014',
		src: 'http://highcourt.cg.gov.in/other/cal2014/dc2014.jpg'
	},
	{
		sr: '17',
		court: 'Subordinate Court',
		year: '2013',
		src: 'http://highcourt.cg.gov.in/other/dc2013.pdf'
	},
	{
		sr: '18',
		court: 'Subordinate Court',
		year: '2012',
		src: 'http://highcourt.cg.gov.in/other/dc2012.pdf'
	},
	{
		sr: '19',
		court: 'Subordinate Court',
		year: '2011',
		src: 'http://highcourt.cg.gov.in/other/dc2011.pdf'
	},
	{
		sr: '20',
		court: 'Subordinate Court',
		year: '2010',
		src: 'http://highcourt.cg.gov.in/other/dc2010.pdf'
	}
];
