/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class NavBar extends Component {
	state = {
		menu: false,
		navBarHeight: 80,
		fontsize: 0
	};

	updateElement = (size) => {
		const elements = document.querySelectorAll('#myP');
		elements.forEach((element) => {
			element.style.fontSize = size;
		});
	};

	updatefontSize = (fontsize) => {
		switch (fontsize) {
			case -3:
				this.updateElement('xx-small');
				this.setState({ fontsize });
				break;

			case -2:
				this.updateElement('x-small');
				this.setState({ fontsize });
				break;

			case -1:
				this.updateElement('small');
				this.setState({ fontsize });
				break;

			case 0:
				this.updateElement('medium');
				this.setState({ fontsize });
				break;

			case 1:
				this.updateElement('large');
				this.setState({ fontsize });
				break;
			case 2:
				this.updateElement('x-large');
				this.setState({ fontsize });
				break;
			case 3:
				this.updateElement('xx-large');
				this.setState({ fontsize });
				break;

			default:
				// this.updateElement("medium");
				// this.setState({ fontsize: 0 });
				break;
		}
	};

	render() {
		const { menu, navBarHeight } = this.state;

		return (
			<React.Fragment>
				<div className="mysticky  right-0 fixed shadow ">
					<a
						className="plus"
						onClick={() => {
							if (this.state.fontsize <= 3) {
								console.log('runnign');
								this.updatefontSize(this.state.fontsize + 1);
							}
						}}
					>
						+ A
					</a>

					<a className="nuteral" onClick={() => this.updatefontSize(0)}>
						<span className="ml-3">A</span>
					</a>
					<a
						class="minus"
						onClick={() => {
							if (this.state.fontsize >= -3) {
								this.updatefontSize(this.state.fontsize - 1);
							}
						}}
					>
						{' '}
						- A
					</a>
				</div>

				<nav
					id="header"
					className="fixed w-full z-30 top-0 text-white gradient"
					style={{
						height: navBarHeight
					}}
				>
					<div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2">
						<div className=" flex items-center">
							<a
								className="toggleColour text-white no-underline hover:no-underline font-bold  lg:text-1xl"
								href="/"
							>
								<div style={{ display: 'flex' }}>
									<img
										src="https://res.cloudinary.com/dcr2pfgxy/image/upload/v1567079077/ag-portal/govlogo.png"
										style={{ height: 70 }}
										alt="Chhattisgarh"
									/>
									<div className="px-2" style={{ flexDirection: 'column' }}>
										<p className="text-black text-2xl">Office Of Advocate General</p>
										<p>Government Of Chhattisgarh</p>
									</div>
								</div>
							</a>
						</div>
						<div className="block lg:hidden pr-4">
							<button
								onClick={() =>
									this.setState({
										menu: !menu,
										navBarHeight: navBarHeight === 80 ? 'auto' : 80
									})}
								id="nav-toggle"
								className="flex items-center px-3 py-2 border rounded text-gray-500 border-gray-600 hover:text-gray-800 hover:border-teal-500 appearance-none focus:outline-none"
							>
								<svg
									className="fill-current h-3 w-3"
									viewBox="0 0 20 20"
									xmlns="http://www.w3.org/2000/svg"
								>
									<title>Menu</title>
									<path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
								</svg>
							</button>
							{menu ? (
								<ul className="list-reset lg:flex justify-end flex-1 items-center">
									<li className="mr-3">
										<Link className="inline-block py-2 px-4 text-black  no-underline" to="/">
											Home
										</Link>
									</li>

									<li className="mr-3">
										<Link className="inline-block py-2 px-4 text-black  no-underline" to="/aboutus">
											About Us
										</Link>
									</li>
									<li className="mr-3">
										<Link
											className="inline-block text-black no-underline hover:text-gray-800 hover:text-underline py-2 px-4"
											to="/tender"
										>
											Tender
										</Link>
									</li>

									<li className="mr-3">
										<Link
											className="inline-block text-black no-underline hover:text-gray-800 hover:text-underline py-2 px-4"
											to="/notification"
										>
											Announcements
										</Link>
									</li>
									<li className="mr-3">
										<Link
											className="inline-block text-black no-underline hover:text-gray-800 hover:text-underline py-2 px-4"
											to="/calendar"
										>
											Calendar
										</Link>
									</li>
								</ul>
							) : null}
						</div>
						<div
							className="w-full flex-grow lg:flex lg:items-center lg:w-auto hidden lg:block mt-2 lg:mt-0 bg-white lg:bg-transparent text-black p-4 lg:p-0 z-20"
							id="nav-content"
						>
							<ul className="list-reset lg:flex justify-end flex-1 items-center">
								<li className="mr-3">
									<Link className="inline-block py-2 px-4 text-black font-bold no-underline" to="/">
										Home
									</Link>
								</li>

								<li className="mr-3">
									<Link className="inline-block py-2 px-4 text-black  no-underline" to="/aboutus">
										About us
									</Link>
								</li>
								<li className="mr-3">
									<Link
										className="inline-block text-black no-underline hover:text-gray-800 hover:text-underline py-2 px-4"
										to="/tender"
									>
										Tender
									</Link>
								</li>
								<li className="mr-3">
									<Link
										className="inline-block text-black no-underline hover:text-gray-800 hover:text-underline py-2 px-4"
										to="/notification"
									>
										Announcements
									</Link>
								</li>
								<li className="mr-3">
									<Link
										className="inline-block text-black no-underline hover:text-gray-800 hover:text-underline py-2 px-4"
										to="/calendar"
									>
										Calendar
									</Link>
								</li>
							</ul>
						</div>
					</div>
					<hr className="border-b border-gray-100 opacity-25 my-0 py-0" />
				</nav>
			</React.Fragment>
		);
	}
}

export default NavBar;
