import React from "react";
import AddGalleryImage from "./AddGalleryImage";

const DashBoardGalleryPage = () => (
  <React.Fragment>
    <div className='bg-blue-800 p-2 shadow text-xl text-white'>
      <h3 className='font-bold pl-2'>Gallery</h3>
    </div>
    <div>
      <AddGalleryImage />
    </div>
  </React.Fragment>
);

export default DashBoardGalleryPage;
