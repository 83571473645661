import React, { Component } from "react";
import { Dot } from "../pages/InformationForIOCPage";
import getfirebase from "../firebase";
const db = getfirebase();

class MarkingStatusPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categary: "Case Marking Status",
      markingStatus: []
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    db.collection("marking_status")
      .get()
      .then(querySnapshot => {
        let blankMarkingStatus = [];
        querySnapshot.forEach(doc => {
          const mydocument = doc.data();
          mydocument.id = doc.id;
          blankMarkingStatus.push(mydocument);
        });
        this.setState({ markingStatus: blankMarkingStatus });
      });
  }

  render() {
    let filterVar = this.state.markingStatus.filter(status => {
      return status.categary === this.state.categary;
    });
    return (
      <React.Fragment>
        <section className="bg-white border-b py-8 container mx-auto">
          <div className="container mx-auto flex flex-wrap pt-4 pb-12">
            <h1 className="w-full my-2 text-2xl font-bold leading-tight text-center text-gray-800">
              Marking Status
            </h1>
            <div className="w-full mb-4">
              <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t" />
            </div>
          </div>

          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-state"
            >
              Select
            </label>
            <select
              className="block appearance-none w-full gradient border border-gray-200 text-white py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-state"
              name="cateogry"
              style={{ zIndex: -100 }}
              onChange={e => {
                this.setState({ categary: e.target.value });
              }}
            >
              <option className="text-black" value="Case Marking Status">
                Case Marking Status
              </option>
              <option className="text-black" value="Fresh State Appeal">
                Fresh State Appeal (criminal)
              </option>
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg
                className="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>

          <ul className="bg-gray-200 flex flex-1 flex-col items-start">
            {filterVar.map((status, i) => {
              return (
                <li
                  key={i}
                  className="flex bg-gray-200 pxy-5 flex-1 items-start"
                  style={{ width: "100%" }}
                >
                  <Dot number={i + 1} />
                  <a className="underline capitalize" href={status.pdflink}>
                    {status.casetitle}
                  </a>
                </li>
              );
            })}
          </ul>
        </section>
      </React.Fragment>
    );
  }
}

export default MarkingStatusPage;

// const MarkingStatus = [
//   {
//     title:
//       "MARKING/APPROVAL STATUS OF FRESH FILING IN CRIMINAL CASES 31.07.2019",
//     url:
//       "https://res.cloudinary.com/dcr2pfgxy/image/upload/v1565255775/ag-portal/files/marking%20status/MARKING_APPROVAL_STATUS_OF_FRESH_FILING_IN_CRIMINAL_CASES_31.07.2019.pdf",
//     category: "1"
//   },
//   {
//     title:
//       "Marking 2019-2020 1806 (9)",
//     url:
//       "https://res.cloudinary.com/dcr2pfgxy/image/upload/v1563959057/ag-portal/files/Marking_2019-2020_1806_9.pdf",
//     category: "1"
//   },
//   {
//     title:
//       "MARKING OF PANEL LAWYER 2019-20 (1).pdf",
//     url:
//       "https://res.cloudinary.com/dcr2pfgxy/image/upload/v1563959048/ag-portal/files/MARKING_OF_PANEL_LAWYER_2019-20_1.pdf",
//     category: "1"
//   },
//   {
//     title:
//       "Marking/Aproval Status of fresh filing in criminal cases dated 22.07.2019",
//     url:
//       "https://res.cloudinary.com/dcr2pfgxy/image/upload/v1563870597/ag-portal/files/marking%20status/Marking_Aproval_Status_of_fresh_filing_in_criminal_cases_dated_22.07.2019.pdf",
//     category: "2"
//   },
//   {
//     title: "Fresh CrMP marking status 17.07.2019 (Amended)",
//     url:
//       "https://res.cloudinary.com/dcr2pfgxy/image/upload/v1563561246/ag-portal/files/marking%20status/Fresh_CrMP_marking_status_17.07.2019_Amended.pdf",
//     category: "1"
//   },
//   {
//     title: "Fresh CrMP Marking status dated 17.07.2019",
//     url:
//       "https://res.cloudinary.com/dcr2pfgxy/image/upload/v1563561351/ag-portal/files/marking%20status/Fresh_CrMP_Marking_status_dated_17.07.2019.pdf",
//     category: "1"
//   },
//   {
//     title:
//       "Marking / approval status of fresh CrMP dt. 12.07.19 and 16.07.19 (amended)",
//     url:
//       "https://res.cloudinary.com/dcr2pfgxy/image/upload/v1563561536/ag-portal/files/marking%20status/marking_approval_status_of_fresh_CrMP_dt._12.07.19_and_16.07.19_amended.pdf",
//     category: "1"
//   },
//   {
//     title: "Fresh crmp marking status 12.07.19 and 16.07.19",
//     url:
//       "https://res.cloudinary.com/dcr2pfgxy/image/upload/v1563560390/ag-portal/files/marking%20status/Fresh_crmp_marking_status_12.07.19_and_16.07.19.pdf",
//     category: "1"
//   },
//   {
//     title: "Status of fresh filing in CrMP from 19.06.2019 to 05.07.2019",
//     url:
//       "https://res.cloudinary.com/dcr2pfgxy/image/upload/v1562749238/ag-portal/files/marking%20status/Status_of_fresh_filing_in_CrMP_from_19.06.2019_to_05.07.2019.pdf",
//     category: "1"
//   },
//   {
//     title: "Marking List of Penal Lawyer on 14.06.2019",
//     url:
//       "https://res.cloudinary.com/dcr2pfgxy/image/upload/v1561834456/ag-portal/files/marking%20status/1.pdf",
//     category: "1"
//   },
//   {
//     title: "Marking/Approval Status of Cases dated 22.06.2019",
//     url:
//       "https://res.cloudinary.com/dcr2pfgxy/image/upload/v1561834459/ag-portal/files/marking%20status/2.pdf",
//     category: "1"
//   },
//   {
//     title:
//       "Marking/Approval Status of Fresh Filling in Criminal Cases dated 19.06.2019",
//     url:
//       "https://res.cloudinary.com/dcr2pfgxy/image/upload/v1561834458/ag-portal/files/marking%20status/3.pdf",
//       category: "2"
//   }
// ];
// export class MarkingStatusPage extends Component {
//   state = {
//     category: "1"
//   };
//   componentDidMount() {
//     window.scrollTo(0, 0);
//   }
//   render() {
//     const markingStatus = MarkingStatus.filter((mar)=>this.state.category === mar.category)
//     return (
//       <section className="bg-white border-b py-8">
//         <div className="container mx-auto flex flex-wrap pt-4 pb-12">
//           <h1 className="w-full my-2 text-2xl font-bold leading-tight text-center text-gray-800">
//             Marking Status
//           </h1>
//           <div className="w-full mb-4">
//             <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t" />
//           </div>
//         </div>

//         <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
//           <label
//             className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
//             htmlFor="grid-state"
//           >
//             Select
//           </label>
//           <div className="relative">
//             <select
//               className="block appearance-none w-full gradient border border-gray-200 text-white py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
//               id="grid-state"
//               name="cateogry"
//               onChange={e => this.setState({ category: e.target.value })}
//               value={this.state.category}
//             >
//               <option value="1">Case Marking Status</option>
//               <option value="2">Fresh State Appeal (criminal)</option>
//             </select>
//             <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
//               <svg
//                 className="fill-current h-4 w-4"
//                 xmlns="http://www.w3.org/2000/svg"
//                 viewBox="0 0 20 20"

//               >
//                 <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
//               </svg>
//             </div>
//           </div>
//         </div>

//         <ul className="bg-gray-200 flex flex-1 flex-col items-start">
//               {markingStatus.map((mar, i) => {
//                   return (
//                     <li
//                       key={i}
//                       className="flex bg-gray-200 pxy-5 flex-1 items-start"
//                       style={{width:'100%'}}
//                     >
//                       <Dot number={i+1} />
//                       <a className="underline" href={mar.url}>{mar.title}</a>
//                     </li>
//                   );
//               })}
//         </ul>
//       </section>
//     );
//   }
// }

// export default MarkingStatusPage;
