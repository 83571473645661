import React, { Component } from 'react';

export class RTIPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <section className='bg-white border-b py-8'>
        <div className='container mx-auto flex flex-wrap pt-4 pb-12' id='myP'>
          <h1 className='w-full my-2 text-2xl font-bold leading-tight text-center text-gray-800'>
            RTI Page
          </h1>
          <div className='w-full mb-4'>
            <div className='h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t' />
          </div>
        </div>
      </section>
    );
  }
}

export default RTIPage;
