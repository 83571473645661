import React, { Component } from "react";

export class WebsitePolicyPage extends Component {
  render() {
    return (
      <React.Fragment>
        <br />
        <div className='container mx-auto shadow-2xl py-4' id='myP'>
          <h1 className='w-full my-2 p-2 mt-2 text-3xl font-bold leading-tight text-center text-gray-800'>
            Website Policy
          </h1>
          <div className='w-full mb-4'>
            <div className='h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t' />
          </div>
          <h2 className='text-2xl px-4 underline'>Terms and Conditions</h2>
          <ul style={{ paddingLeft: 10, paddingRight: 10 }}>
            <li className=' w-full cursor-text  p-2 mx-auto'>
              Contents on this website is published, managed and maintained by
              Office of the Advocate General,Govt. Of Chhattisgarh.
            </li>
            <li className=' w-full cursor-text  p-2 mx-auto'>
              Though all efforts have been made to ensure the accuracy and
              currency of the content on this website, the same should not be
              construed as a statement of law or used for any legal purposes. In
              case of any ambiguity or doubts, users are advised to verify /
              check with the Department(s) and / or other source(s), and to
              obtain appropriate professional advice.
            </li>
            <li className=' w-full cursor-text  p-2  mx-auto'>
              Under no circumstances will this Department be liable for any
              expense, loss or damage including, without limitation, indirect or
              consequential loss or damage, or any expense, loss or damage
              whatsoever arising from use, or loss of use, of data, arising out
              of or in connection with the use of this website.
            </li>
            <li className=' w-full cursor-text  p-2  mx-auto'>
              These terms and conditions shall be governed by and constructed in
              accordance with the Indian Laws. Any dispute arising under these
              terms and conditions shall be subject to the jurisdiction of the
              courts of India.
            </li>
            <li className=' w-full cursor-text  p-2  mx-auto'>
              The information posted on this website could include hypertext
              links or pointers to information created and maintained by
              non-Government / private organizations. Office of the Advocate
              General,Govt. Of Chhattisgarh is providing these links and
              pointers solely for your information and convenience. When you
              select a link to an outside website, you are leaving the Office of
              the Advocate General,Govt. Of Chhattisgarh website and are subject
              to the privacy and security policies of the owners / sponsors of
              the outside website. Office of the Advocate General,Govt. Of
              Chhattisgarh Organization, does not guarantee the availability of
              such linked pages at all times.
            </li>
          </ul>
          <br />
          <hr width='97%' style={{ textAlign: "center", margin: "0 auto" }} />
          <br />
          <h2 className='text-2xl px-4 underline'>Privacy Policy</h2>

          <ul style={{ paddingLeft: 10, paddingRight: 10 }}>
            <li className=' w-full cursor-text  p-2 bg-gr mx-auto'>
              As a general rule, this website does not collect Personal
              Information about you when you visit the site. You can generally
              visit the site without revealing Personal Information, unless you
              choose to provide such information.
            </li>
            <h4 className='font-bold px-2'>Site Visit data:</h4>
            <li className=' w-full cursor-text  p-2  mx-auto'>
              This website records your visit and logs the following information
              for statistical purposes your server's address; the name of the
              top-level domain from which you access the Internet (for example,
              .gov, .com, .in, etc.); the type of browser you use; the date and
              time you access the site; the pages you have accessed and the
              documents downloaded and the previous Internet address from which
              you linked directly to the site.
            </li>
            <li className=' w-full cursor-text  p-2  mx-auto'>
              We will not identify users or their browsing activities, except
              when a law enforcement agency may exercise a warrant to inspect
              the service provider's logs.
            </li>
            <h4 className='font-bold px-2'>Cookies:</h4>

            <li className=' w-full cursor-text  p-2  mx-auto'>
              A cookie is a piece of software code that an internet web site
              sends to your browser when you access information at that site.
              This site does not use cookies.
            </li>
            <h4 className='font-bold px-2'>Email management:</h4>

            <li className=' w-full cursor-text  p-2  mx-auto'>
              Your email address will only be recorded if you choose to send a
              message. It will only be used for the purpose for which you have
              provided it and will not be added to a mailing list. Your email
              address will not be used for any other purpose, and will not be
              disclosed, without your consent.
            </li>
            <h4 className='font-bold px-2 '>
              Collection of Personal Information:
            </h4>
            <li className=' w-full cursor-text  p-2  mx-auto'>
              If you are asked for any other Personal Information you will be
              informed how it will be used if you choose to give it. If at any
              time you believe the principles referred to in this privacy
              statement have not been followed, or have any other comments on
              these principles, please notify the webmaster through the contact
              us page.
            </li>
            <h4 className='font-bold px-2'>Note:</h4>
            <li className=' w-full cursor-text  p-2  mx-auto'>
              The use of the term "Personal Information" in this privacy
              statement refers to any information from which your identity is
              apparent or can be reasonably ascertained.
            </li>
          </ul>
          <br />
          <hr width='97%' style={{ textAlign: "center", margin: "0 auto" }} />
          <br />
          <h2 className='text-2xl px-4 underline'>Hyperlinking Policy</h2>

          <ul style={{ paddingLeft: 10, paddingRight: 10 }}>
            <li className=' w-full cursor-text  p-2 mx-auto'>
              We do not object to you linking directly to the information that
              is hosted on our site and no prior permission is required for the
              same. However, we would like you to inform us about any links
              provided to our site so that you can be informed of any changes or
              updations therein. Also, we do not permit our pages to be loaded
              into frames on your site. Our website's pages must load into a
              newly opened browser window of the user.
            </li>
          </ul>
          <br />
          <hr width='97%' style={{ textAlign: "center", margin: "0 auto" }} />
          <br />
          <h2 className='text-2xl px-4 underline'>Copyright Policy</h2>

          <ul style={{ paddingLeft: 10, paddingRight: 10 }}>
            <li className=' w-full cursor-text  p-2 mx-auto'>
              Contents of this website may not be reproduced partially or fully,
              without due permission from Relive Technology. If referred to as a
              part of another website, the source must be appropriately
              acknowledged. The contents of this website can not be used in any
              misleading or objectionable context.
            </li>
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

export default WebsitePolicyPage;
