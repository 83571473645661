import React, { Component } from "react";

export class PrivacyPolicyPage extends Component {
  render() {
    return (
      <React.Fragment>
        <br />
        <div className='container mx-auto shadow-2xl py-4' id='myP'>
          <h1 className='w-full my-2 p-2 mt-2 text-3xl font-bold leading-tight text-center text-gray-800'>
            Privacy Policy
          </h1>
          <div className='w-full mb-4'>
            <div className='h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t' />
          </div>
          <h2 className='text-2xl px-4 underline'>Privacy Policy</h2>

          <ul style={{ paddingLeft: 10, paddingRight: 10 }}>
            <li className=' w-full cursor-text  p-2 bg-gr mx-auto'>
              As a general rule, this website does not collect Personal
              Information about you when you visit the site. You can generally
              visit the site without revealing Personal Information, unless you
              choose to provide such information.
            </li>
            <h4 className='font-bold px-2'>Site Visit data:</h4>
            <li className=' w-full cursor-text  p-2  mx-auto'>
              This website records your visit and logs the following information
              for statistical purposes your server's address; the name of the
              top-level domain from which you access the Internet (for example,
              .gov, .com, .in, etc.); the type of browser you use; the date and
              time you access the site; the pages you have accessed and the
              documents downloaded and the previous Internet address from which
              you linked directly to the site.
            </li>
            <li className=' w-full cursor-text  p-2  mx-auto'>
              We will not identify users or their browsing activities, except
              when a law enforcement agency may exercise a warrant to inspect
              the service provider's logs.
            </li>
            <h4 className='font-bold px-2'>Cookies:</h4>

            <li className=' w-full cursor-text  p-2  mx-auto'>
              A cookie is a piece of software code that an internet web site
              sends to your browser when you access information at that site.
              This site does not use cookies.
            </li>
            <h4 className='font-bold px-2'>Email management:</h4>

            <li className=' w-full cursor-text  p-2  mx-auto'>
              Your email address will only be recorded if you choose to send a
              message. It will only be used for the purpose for which you have
              provided it and will not be added to a mailing list. Your email
              address will not be used for any other purpose, and will not be
              disclosed, without your consent.
            </li>
            <h4 className='font-bold px-2 '>
              Collection of Personal Information:
            </h4>
            <li className=' w-full cursor-text  p-2  mx-auto'>
              If you are asked for any other Personal Information you will be
              informed how it will be used if you choose to give it. If at any
              time you believe the principles referred to in this privacy
              statement have not been followed, or have any other comments on
              these principles, please notify the webmaster through the contact
              us page.
            </li>
            <h4 className='font-bold px-2'>Note:</h4>
            <li className=' w-full cursor-text  p-2  mx-auto'>
              The use of the term "Personal Information" in this privacy
              statement refers to any information from which your identity is
              apparent or can be reasonably ascertained.
            </li>
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

export default PrivacyPolicyPage;
