import React, { Component } from "react";
import { Link } from "react-router-dom";

export class SideNavBar extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="bg-gray-900 shadow-lg h-16 fixed bottom-0 mt-12 md:relative md:h-screen z-10 w-full md:w-48">
          <div className="md:mt-12 md:w-48 md:fixed md:left-0 md:top-0 content-center md:content-start text-left justify-between">
            <ul className="list-reset flex flex-row md:flex-col py-0 md:py-3 px-1 md:px-2 text-center md:text-left">
              <li className="mr-3 flex-1">
                <Link
                  to="/dashboard"
                  className="block py-1 md:py-3 pl-1 align-middle text-white no-underline hover:text-white border-b-2 border-gray-800 hover:border-pink-500"
                >
                  <i className="fas fa-tasks pr-0 md:pr-3" />
                  <span className="pb-1 md:pb-0 text-xs md:text-base text-gray-600 md:text-gray-400 block md:inline-block">
                    Marking Status
                  </span>
                </Link>
              </li>
              <li className="mr-3 flex-1">
                <Link
                  to="/dashboardgallery"
                  className="block py-1 md:py-3 pl-1 align-middle text-white no-underline hover:text-white border-b-2 border-gray-800 hover:border-purple-500"
                >
                  <i className="fa fa-envelope pr-0 md:pr-3" />
                  <span className="pb-1 md:pb-0 text-xs md:text-base text-gray-600 md:text-gray-400 block md:inline-block">
                    Gallery
                  </span>
                </Link>
              </li>
              <li className="mr-3 flex-1">
                <Link
                  to="/dashboardtender"
                  className="block py-1 md:py-3 pl-1 align-middle text-white no-underline hover:text-white border-b-2 border-gray-800 hover:border-purple-500"
                >
                  <i className="fa fa-envelope pr-0 md:pr-3" />
                  <span className="pb-1 md:pb-0 text-xs md:text-base text-gray-600 md:text-gray-400 block md:inline-block">
                    Tender
                  </span>
                </Link>
              </li>
              <li className="mr-3 flex-1">
                <Link
                  to="/dashboardannouncement"
                  className="block py-1 md:py-3 pl-1 align-middle text-white no-underline hover:text-white border-b-2 border-gray-800 hover:border-purple-500"
                >
                  <i className="fa fa-envelope pr-0 md:pr-3" />
                  <span className="pb-1 md:pb-0 text-xs md:text-base text-gray-600 md:text-gray-400 block md:inline-block">
                    Announcement
                  </span>
                </Link>
              </li>

              <li className="mr-3 flex-1">
                <Link
                  to="/lawofficers"
                  className="block py-1 md:py-3 pl-1 align-middle text-white no-underline hover:text-white border-b-2 border-gray-800 hover:border-purple-500"
                >
                  <i className="fa fa-envelope pr-0 md:pr-3" />
                  <span className="pb-1 md:pb-0 text-xs md:text-base text-gray-600 md:text-gray-400 block md:inline-block">
                    Add Law Officers
                  </span>
                </Link>
              </li>

              <li className="mr-3 flex-1">
                <Link
                  to="/addagprofile"
                  className="block py-1 md:py-3 pl-1 align-middle text-white no-underline hover:text-white border-b-2 border-gray-800 hover:border-purple-500"
                >
                  <i className="fa fa-envelope pr-0 md:pr-3" />
                  <span className="pb-1 md:pb-0 text-xs md:text-base text-gray-600 md:text-gray-400 block md:inline-block">
                    Add Ag profile
                  </span>
                </Link>
              </li>
              <li className="mr-3 flex-1">
                <Link
                  to="/viewlawofficersdata"
                  className="block py-1 md:py-3 pl-1 align-middle text-white no-underline hover:text-white border-b-2 border-gray-800 hover:border-purple-500"
                >
                  <i className="fa fa-envelope pr-0 md:pr-3" />
                  <span className="pb-1 md:pb-0 text-xs md:text-base text-gray-600 md:text-gray-400 block md:inline-block">
                    View Law Officers Data
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SideNavBar;
