import React, { Component } from "react";
import { NotificationManager } from "react-notifications";
import getfirebase from "../../firebase";

const db = getfirebase();

export class DashBoardTenderPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tenderNo: "",
      tenderTitle: "",
      tenderStartDate: "",
      tenderDocLink: "",
      tenderEndDate: "",
      tenderListings: []
    };
  }

  componentDidMount() {
    const myWidget = window.cloudinary.createUploadWidget(
      {
        cloudName: process.env.NODE_ENV === "development" ? "msahu2595" : "dcr2pfgxy",
        uploadPreset: process.env.NODE_ENV === "development" ? "e3rej1yz" : "ubtzxk3n"
      },
      (error, result) => {
        if (!error && result && result.event === "success") {
          if (result.info.url) {
            NotificationManager.success("Uploaded Successfully.", "Success");
            this.setState({ tenderDocLink: result.info.url });
          } else {
            NotificationManager.error("Something Went Wrong.");
          }
        }
      }
    );

    document.getElementById("tender_upload_widget").addEventListener(
      "click",
      function () {
        myWidget.open();
      },
      false
    );

    db.collection("tender_listings")
      .get()
      .then(querySnapshot => {
        let emptyTenderlist = [];
        querySnapshot.forEach(doc => {
          const mydocument = doc.data();
          mydocument.id = doc.id;
          emptyTenderlist.push(mydocument);
        });
        this.setState({ tenderListings: emptyTenderlist });
      });
  }

  handleSubmit = e => {
    e.preventDefault();
    const {
      tenderNo,
      tenderTitle,
      tenderStartDate,
      tenderEndDate,
      tenderDocLink
    } = this.state;
    if (tenderNo === "") {
      NotificationManager.warning("Enter Title Number");
      return;
    }
    if (tenderTitle === "") {
      NotificationManager.warning("Enter Tender Title");
      return;
    }
    if (tenderStartDate === "") {
      NotificationManager.warning("Enter Tender Start Date");
      return;
    }
    if (tenderEndDate === "") {
      NotificationManager.warning("Enter Tender End Date");
      return;
    }
    if (tenderDocLink === "") {
      NotificationManager.warning("Please Upload File");
      return;
    }
    db.collection("tender_listings")
      .add({
        tenderNo,
        tenderTitle,
        tenderStartDate,
        tenderEndDate,
        tenderDocLink
      })
      .then(docRef => {
        if (docRef.id) {
          NotificationManager.success("Submited Successfully", "Success");
          const tenders = this.state.tenderListings;
          tenders.unshift({
            id: docRef.id,
            tenderNo,
            tenderTitle,
            tenderStartDate,
            tenderEndDate,
            tenderDocLink
          });
          this.setState({ tenderListings: tenders });
          this.setState({
            tenderNo: "",
            tenderTitle: "",
            tenderStartDate: "",
            tenderDocLink: "",
            tenderEndDate: ""
          });
        } else {
          NotificationManager.error("Something Went Wrong.");
        }
      })
      .catch(error => {
        console.error("Error Adding Document: ", error);
      });
  };

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  handleDelete = id => {
    db.collection("tender_listings")
      .doc(id)
      .delete()
      .then(() => {
        NotificationManager.success(
          "Document Successfully Deleted!",
          "Success"
        );
        const tenders = this.state.tenderListings;
        const myLatestTenders = tenders.filter(s => s.id !== id);
        this.setState({ markingtenders: myLatestTenders });
      })
      .catch(function (error) {
        console.error("Error Removing Document: ", error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <div className="bg-blue-800 p-2 shadow text-xl text-white">
          <h3 className="font-bold pl-2">TENDER</h3>
        </div>
        <form
          className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
          onSubmit={this.handleSubmit}
          name="markingstatusform"
        >
          <div className="mb-4">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-first-name"
            >
              Tender Number
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              placeholder="Tender Number"
              name="tenderNo"
              value={this.state.tenderNo}
              onChange={this.handleChange}
            />
          </div>
          <div className="mb-4">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-first-name"
            >
              Tender Title
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              placeholder="Tender Title"
              name="tenderTitle"
              value={this.state.tenderTitle}
              onChange={this.handleChange}
            />
          </div>
          <div className="mb-4">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-first-name"
            >
              Tender Start Date
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="date"
              name="tenderStartDate"
              value={this.state.tenderStartDate}
              onChange={this.handleChange}
            />
          </div>
          <div className="mb-4">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-last-name"
            >
              File Upload
            </label>
            <button
              type="button"
              id="tender_upload_widget"
              className="cloudinary-button"
            >
              Upload files
            </button>
          </div>
          <div className="mb-4">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-first-name"
            >
              End Date
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="date"
              name="tenderEndDate"
              value={this.state.tenderEndDate}
              onChange={this.handleChange}
            />
          </div>
          <div className="mb-4">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
              type="submit"
            >
              SUBMIT
            </button>
          </div>
        </form>
        <div className="bg-blue-800 p-2 shadow text-xl text-white">
          <h3 className="font-bold pl-2">TENDER LISTINGS</h3>
        </div>
        {this.state.tenderListings.map((tender, i) => {
          return (
            <div
              key={i}
              className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
            >
              <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2 capitalize">
                  Tender No. : {tender.tenderNo}
                </div>
                <p className="text-gray-700 text-base">
                  Title : {tender.tenderTitle}
                </p>
                <p className="text-gray-700 text-base">
                  Title : {tender.tenderStartDate}
                </p>
                <p className="text-gray-700 text-base">
                  Title : {tender.tenderEndDate}
                </p>
              </div>
              <div className="px-6 py-4">
                <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2">
                  File Link :
                  <a
                    href={tender.tenderDocLink}
                    style={{
                      fontFamily: "Arial",
                      color: "red",
                      textDecoration: "underline"
                    }}
                  >
                    Click to See File.
                  </a>
                </span>
              </div>
              <div className="px-6 py-4">
                <button
                  name="deleteButton"
                  onClick={() => this.handleDelete(tender.id)}
                  className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                >
                  Delete
                </button>
              </div>
            </div>
          );
        })}
      </React.Fragment>
    );
  }
}

export default DashBoardTenderPage;
