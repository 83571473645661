/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';

export class AboutusPage extends Component {
	render() {
		return (
			<React.Fragment>
				<br />
				<div className="container mx-auto shadow-2xl py-4" id="myP">
					<h1 className="w-full my-2 text-3xl font-bold leading-tight text-center text-gray-800">
						Hon'ble Advocate General Chhattisgarh
					</h1>
					<div className="flex flex-wrap">
						<div className="flex-1  text-center  px-10 py-2 m-2" style={{ textAlign: 'justify' }}>
						Mr. Praphulla Kumar Bharat was born on 22nd of June, 1966. Did his schooling at Jagdalpur, and after having passed Post Gradation (M.A.) passed L.L.B. and was enrolled with Bar Council of M.P., Jabalpur in the year 1992. Started practicing initially at District Court Jagdalpur District Bastar between the period from 1992 till 1995 and thereafter, at High Court of Madhya Pradesh, Jabalpur from 1995 till October, 2000 and subsequently at the High Court of Chhattisgarh, Bilaspur from November, 2000 onwards. Appeared before the High Court of Chhattisgarh in maximum cases having specialization in Civil, Constitutional, Arbitration and Criminal matters. Appeared in cases of various autonomous bodies like Zila Sahakari Kendriya Bank Maryadit Jagdalpur, Bastar Kshetriya Gramin Bank Jagdalpur, Mata Rukmani Sewa Sansthan Dimrapal, CG. State Cooperative Marketing Federation Ltd., State Bank of India, Atal Nagar Development Authority (Former NRDA) and CG. State Co-operative Bank, Raipur (Apex). Appointed as Additional Advocate General, State of Chhattisgarh, during the tenure of the former Chief Minister Dr. Raman Singh, BJP Government from 2014 till 2018. Designated as Senior Advocate, High Court of Chhattisgarh on 14/06/2021. Appointed as Advocate General, State of Chhattisgarh on 12/01/2024. 
						</div>

						<div className="flex-2  text-gray-700 text-center  px-4 py-2 m-2" style={{ minWidth: 300 }}>
							<img
								className=" w-24 md:w-32 "
								style={{ width: '100%', height: '300px' }}
								src="https://i.ibb.co/gR93WGs/advocate-general-cg.png"
							/>
						</div>
					</div>

					<hr width="97%" style={{ textAlign: 'center', margin: '0 auto' }} />

					<h1 className="w-full my-2 p-2 mt-2 text-3xl font-bold leading-tight text-center text-gray-800">
						Role And Function
					</h1>
					<div className="w-full mb-4">
						<div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t" />
					</div>

					<h2 className="text-2xl px-4 underline">Role And Function</h2>

					<ul style={{ paddingLeft: 10, paddingRight: 10 }}>
						<li className=" w-full cursor-text  p-2  mx-auto">
							The Advocate General of a State is a Constitutional post and authority duly appointed as per
							Article 165 of the Constitution of India. The authority and function of Advocate General is
							also specified in the Constitution of India under Article 165 and 177.
						</li>
						<h4 className="font-bold px-2">Article 165: Advocate General for the State</h4>
						<li className=" w-full cursor-text  p-2  mx-auto">
							The Governor of each State shall appoint a person who is qualified to be appointed as a
							Judge of a High Court to be Advocate General for the State.
						</li>

						<li className=" w-full cursor-text  p-2  mx-auto">
							It shall be the duty of the Advocate General to give advice to the Government of concerned
							State upon such legal matters and to perform such other duties of a legal character; as may
							from time to time be referred or assigned to him by the Governor and to discharge the
							functions conferred on him by or under this constitution or any other law for the time being
							in force.{' '}
						</li>
						<li className=" w-full cursor-text  p-2  mx-auto">
							The Advocate General shall hold office during the pleasure of the Governor, and shall
							receive such remuneration as the governor may determine.
						</li>
						<h4 className="font-bold px-2">Article 177:</h4>
						<li className=" w-full cursor-text  p-2  mx-auto">
							Every Minister and the Advocate-General for a State shall have the right to speak-in, and
							otherwise take part in the proceedings of Legislative Assembly of the State or in case of a
							State having a Legislative Council; Both Houses, and to speak-in, and otherwise to take part
							in the proceedings of, any committee of the Legislature of which he may be named a member
							but shall not; by virtue of this Article, be entitled to vote.
						</li>
						<li className=" w-full cursor-text  p-2  mx-auto">
							The Hon’ble Supreme Court of India taking into account the above mentioned Articles has held
							that:
						</li>
						<li className=" w-full cursor-text  p-2  mx-auto">
							<strong>" 12:</strong> The Office of Advocate General is an exalted one. He is the Supreme
							law officer of the State"
						</li>
						<li className=" w-full cursor-text  p-2  mx-auto">
							<strong>" 18:</strong> Under Article 177 he is conferred with the right to audience before
							the Legislature of a State both in the Assembly and the Council. Infact, he is treated on at
							par with Minister."
						</li>
						<li className=" w-full cursor-text  p-2  mx-auto">
							<strong>Reference 1: (1994) Supreme Court Cases 184</strong> Joginder Singh Wasu v/s State
							of Punjab The Advocate General and his Law officers are basically engaged to deal with court
							cases in the High Court by State Government and the relationship between the Government and
							Law Officers is that of a client and counsel.
						</li>
						<li className=" w-full cursor-text  p-2  mx-auto">
							<strong>Reference 2: (1994) SCC 204</strong> State of U.P. & Others v/s U.P. State Law
							Officers Associations & others The Advocate General and his office defends and protects the
							interest of State Government and gives invaluable legal guidance to the State Government in
							formulation of its policy and execution of its decisions.
						</li>
					</ul>
				</div>
			</React.Fragment>
		);
	}
}

export default AboutusPage;
