/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../components/NavBar';

export class LandingLayout extends Component {
	render() {
		return (
			<div>
				<NavBar />
				<div style={{ marginTop: 80 }}>{this.props.children}</div>
				<footer className="bg-white">
					<div
						style={{
							padding: '20px 0 10px 0',
							display: 'flex',
							flexFlow: 'row',
							justifyContent: 'space-around',
							flexWrap: 'wrap',
							flexBasis: 200
						}}
					>
						<div style={{ display: 'flex' }}>
							<a href="https://www.india.gov.in/" target="_blank">
								<img
									src="https://res.cloudinary.com/dcr2pfgxy/image/upload/v1561521452/ag-portal/indiagov.png.png"
									style={{ width: '200px' }}
								/>
							</a>
						</div>
						<div style={{ display: 'flex' }}>
							<a href="http://highcourt.cg.gov.in/" target="_blank">
								<img
									src="https://res.cloudinary.com/dcr2pfgxy/image/upload/v1561521431/ag-portal/highcourt.png.png"
									style={{ width: '200px' }}
								/>
							</a>
						</div>
						<div style={{ display: 'flex' }}>
							<a href="https://cgstate.gov.in/" target="_blank">
								<img
									src="https://res.cloudinary.com/dcr2pfgxy/image/upload/v1561521536/ag-portal/cg-gov.png.png"
									style={{ width: '200px' }}
								/>
							</a>
						</div>

						<div style={{ display: 'flex' }}>
							<a href="https://www.digitalindia.gov.in/" target="_blank">
								<img
									src="https://res.cloudinary.com/dcr2pfgxy/image/upload/v1561521546/ag-portal/digital-india.png.png"
									style={{ width: '200px' }}
								/>
							</a>
						</div>
					</div>

					<div className="container mx-auto  px-8">
						<div className="w-full flex flex-col md:flex-row py-6">
							<div className="flex-1 mb-6">
								<a
									className="text-orange-600 no-underline hover:no-underline font-bold text-2xl lg:text-4xl"
									href="#"
								>
									<img
										src="https://res.cloudinary.com/dcr2pfgxy/image/upload/v1567079077/ag-portal/govlogo.png"
										alt="Chhattisgarh"
										style={{ width: '160px' }}
									/>
								</a>
							</div>
							<div className="flex-1">
								<p className="uppercase text-gray-500 md:mb-6">Law Officers</p>
								<ul className="list-reset mb-6">
									<li className="mt-2 inline-block mr-2 md:block md:mr-0">
										<Link
											to="/agprofile"
											className="no-underline hover:underline text-gray-800 hover:text-orange-500"
										>
											AG Profile
										</Link>
									</li>
									<li className="mt-2 inline-block mr-2 md:block md:mr-0">
										<Link
											to="/formerag"
											className="no-underline hover:underline text-gray-800 hover:text-orange-500"
										>
											Former AG's
										</Link>
									</li>
									<li className="mt-2 inline-block mr-2 md:block md:mr-0">
										<Link
											to="/role"
											className="no-underline hover:underline text-gray-800 hover:text-orange-500"
										>
											Role and functions
										</Link>
									</li>
								</ul>
							</div>
							<div className="flex-1">
								<p className="uppercase text-gray-500 md:mb-6">Nodal Officers</p>
								<ul className="list-reset mb-6">
									<li className="mt-2 inline-block mr-2 md:block md:mr-0">
										<Link
											to="/officers"
											className="no-underline hover:underline text-gray-800 hover:text-orange-500"
										>
											Law Officers
										</Link>
									</li>
									<li className="mt-2 inline-block mr-2 md:block md:mr-0">
										<Link
											to="/emadvocate"
											className="no-underline hover:underline text-gray-800 hover:text-orange-500"
										>
											Empanelled Advocates
										</Link>
									</li>
								</ul>
							</div>

							<div className="flex-1">
								<p className="uppercase text-gray-500 md:mb-6">Useful Links</p>
								<ul className="list-reset mb-6">
									<li className="mt-2 inline-block mr-2 md:block md:mr-0">
										<Link
											to="/terms"
											className="no-underline hover:underline text-gray-800 hover:text-orange-500"
										>
											Terms and conditions
										</Link>
									</li>
									<li className="mt-2 inline-block mr-2 md:block md:mr-0">
										<Link
											to="/privacy"
											className="no-underline hover:underline text-gray-800 hover:text-orange-500"
										>
											Privacy Policy
										</Link>
									</li>

									<li className="mt-2 inline-block mr-2 md:block md:mr-0">
										<Link
											to="/websitepolicy"
											className="no-underline hover:underline text-gray-800 hover:text-orange-500"
										>
											Website Policy
										</Link>
									</li>
									<li className="mt-2 inline-block mr-2 md:block md:mr-0">
										<Link
											to="/ioc"
											className="no-underline hover:underline text-gray-800 hover:text-orange-500"
										>
											Information for O.I.C.
										</Link>
									</li>
									<li className="mt-2 inline-block mr-2 md:block md:mr-0">
										<Link
											to="/markstatus"
											className="no-underline hover:underline text-gray-800 hover:text-orange-500"
										>
											Marking status
										</Link>
									</li>
									<li className="mt-2 inline-block mr-2 md:block md:mr-0">
										<Link
											to="/rti"
											className="no-underline hover:underline text-gray-800 hover:text-orange-500"
										>
											RTI
										</Link>
									</li>
								</ul>
							</div>

							<div className="flex-1">
								<p className="uppercase text-gray-500 md:mb-6">Contact Us</p>
								<ul className="list-reset mb-6">
									<li className="mt-2 inline-block mr-2 md:block md:mr-0">
										<a
											href="http://highcourt.cg.gov.in/"
											className="no-underline hover:underline text-gray-800 hover:text-orange-500"
										>
											Chhattisgarh Highcourt Bilaspur
										</a>
									</li>
									<li className="mt-2 inline-block mr-2 md:block md:mr-0">
										<a
											href="mailto:agcgbsp@gmail.com"
											className="no-underline hover:underline text-gray-800 hover:text-orange-500"
										>
											agcgbsp@gmail.com
										</a>
									</li>

									<li className="mt-2 inline-block mr-2 md:block md:mr-0">
										<Link
											to="/contactus"
											className="no-underline hover:underline text-gray-800 hover:text-orange-500"
										>
											Internship
										</Link>
									</li>
									<li className="mt-2 inline-block mr-2 md:block md:mr-0">
										<Link
											to="/contactus"
											className="no-underline hover:underline text-gray-800 hover:text-orange-500"
										>
											Contact Us
										</Link>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="px-4 py-5 text-center font-bold bg-black">
						<p className="text-white">
							Website owned & maintained by Advocate General Office Bilaspur High Court{' '}
						</p>
					</div>
				</footer>
			</div>
		);
	}
}

export default LandingLayout;
