/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import getDatabase from "../firebase";

const db = getDatabase();

export class LawOfficersPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: null,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    db.collection("lawofficers")
      .orderBy("seq", "asc")
      .get()
      .then((snapshot) => {
        const users = [];
        snapshot.forEach((doc) => {
          const data = doc.data();
          users.push(data);
        });
        this.setState({ users: users });
      })
      .catch((error) => console.log(error));
  }
  render() {
    const { users } = this.state;
    console.log(users);
    return (
      <section className="bg-white border-b ">
        <div className="container mx-auto flex flex-wrap pt-4 pb-12" id="myP">
          <h1 className="w-full my-2 text-2xl font-bold leading-tight text-center text-gray-800">Law Officers</h1>
          <div className="w-full mb-4">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t" />
          </div>
          <table className="text-center shadow-lg w-full">
            <thead className=" flex w-full bg-black text-white">
              <tr className="flex text-left w-full">
                <th style={{ width: 50 }}>Sr.No.</th>
                <th style={{ flex: 3 }}>Name Of Law Officer</th>
                <th style={{ flex: 3 }}>Designation</th>
                <th style={{ flex: 1 }}>Office Contact</th>
                <th style={{ flex: 1 }}>Mobile Number</th>
                <th style={{ flex: 1 }}>WhatsApp</th>
                <th style={{ flex: 1 }}>Email</th>
              </tr>
            </thead>
            {/* Remove the nasty inline CSS fixed height on production and replace it with a CSS class — this is just for demonstration purposes! */}
            <tbody className="flex flex-col items-center justify-between overflow-y-scroll w-full">
              {users &&
                users.map((ag, i) => {
                  return (
                    <tr className="flex w-full text-left" key={i}>
                      <td style={{ width: 50, height: 150, border: "1px solid black", overflowY: "scroll" }}>{i + 1}</td>
                      <td style={{ flex: 3, height: 150, border: "1px solid black", overflowY: "scroll" }}>{ag.name}</td>
                      <td style={{ flex: 3, height: 150, border: "1px solid black", overflowY: "scroll" }}>{ag.designation}</td>
                      <td style={{ flex: 1, height: 150, border: "1px solid black", overflowY: "scroll" }}>{ag.officecontact}</td>
                      <td style={{ flex: 1, height: 150, border: "1px solid black", overflowY: "scroll" }}>{ag.mobilenumber}</td>
                      <td style={{ flex: 1, height: 150, border: "1px solid black", overflowY: "scroll" }}>{ag.whatsapp}</td>
                      <td style={{ flex: 1, height: 150, border: "1px solid black", overflowY: "scroll" }}>{ag.email}</td>
                    </tr>
                  );
                })}

              {/* {Advocates.map((ag, i) => {
                return (
                  <tr className="flex w-full mb-4  items-left" key={i}>
                    <td className="">{i + 1}</td>
                    <td className="p-4 w-1/4">{ag.name}</td>
                    <td className="p-4 w-1/4">{ag.designation}</td>
                    <td className="p-4 w-1/4">
                      {ag.office === "" ? "---" : `07752-${ag.office}`}
                    </td>
                    <td className="p-4 w-1/4">
                      {ag.mob === "" ? "---" : ag.mob}
                    </td>
                    <td className="p-4 w-1/4">
                      {ag.gmail === "" ? (
                        "---"
                      ) : (
                        <a
                          style={{ color: "blue" }}
                          href={`mailto:${ag.gmail}`}
                        >
                          {ag.gmail}
                        </a>
                      )}
                    </td>
                  </tr>
                );
              })} */}
            </tbody>
          </table>
        </div>
      </section>
    );
  }
}

export default LawOfficersPage;

const Advocates = [
  {
    sr: "1",
    name: `SHRI SATISH CHANDRA VERMA `,
    designation: `HON'BLE ADVOCATE GENERAL`,
    ofccont: "",
    mob: "9827115912",
    office: "241303",
    mobw: "9827115912",
    gmail: "scvermaadvocate@gmail.com",
  },
  {
    sr: "2",
    name: "SHRI ALOK BAKSHI",
    designation: "Addl. Advocate General",
    ofccont: "",
    mob: "94252-23997",
    office: "241358",
    mobw: "9200923997",
    gmail: "alokbakshi43@gmail.com",
  },
  {
    sr: "3",
    name: "SMT. FOUZIA MIRZA,",
    designation: "Addl. Advocate General",
    ofccont: "",
    mob: "98939-18000",
    office: "241341",
    mobw: "9827946648",
    gmail: "fouziamirza@gmail.com",
  },
  {
    sr: "4",
    name: "SHRI VIVEK RANJAN TIWARI",
    designation: "Addl. Advocate General",
    ofccont: "",
    mob: "98271-17492",
    office: "241344",
    mobw: "98271-17492",
    gmail: "vivekranjanadvocate@gmail.com",
  },
  {
    sr: "5",
    name: "SHRI AMRITO DAS, ",
    designation: "Addl. Advocate General",
    ofccont: "",
    mob: "9827302894",
    office: "241342",
    mobw: "9827302894",
    gmail: "",
  },
  {
    sr: "6",
    name: "SMT. HAMIDA SIDDIQUI, ",
    designation: "Deputy Advocate General",
    ofccont: "",
    mob: "98261-20216",
    office: "",
    mobw: "98261-20216",
    gmail: "",
  },
  {
    sr: "7",
    name: "SHRI JITENDRA PALI,",
    designation: "Deputy Advocate General",
    ofccont: "",
    mob: "9907901614",
    office: "241345",
    mobw: "9907901614",
    gmail: "jitenpali@yahoo.com",
  },
  {
    sr: "8",
    name: "SHRI HARPREET SINGH AHLUWALIA",
    designation: "Deputy Advocate General",
    ofccont: "",
    mob: "9300855360,9827153072",
    office: "241339",
    mobw: "9300855360",
    gmail: "hrprtahluwalia@gmail.com",
  },
  {
    sr: "9",
    name: "SHRI CHANDRESH SHRIVASTAVA",
    designation: "Deputy Advocate General",
    ofccont: "",
    mob: "94252-31201",
    office: "",
    mobw: "94252-31201",
    gmail: "chandreshshrivastava@gmail.com",
  },
  {
    sr: "10",
    name: "SHRI DEVENDRA PRATAP SINGH",
    designation: "Deputy Advocate General",
    ofccont: "",
    mob: "98932-11818",
    office: "",
    mobw: "98932-11818",
    gmail: "devendrabsp@yahoo.com",
  },
  {
    sr: "12",
    name: "SHRI MATEEN SIDDIQUI",
    designation: "Deputy Advocate General",
    ofccont: "",
    mob: "9827166220",
    office: "",
    mobw: "9827166220",
    gmail: "",
  },
  {
    sr: "13",
    name: "SHRI SUDEEP AGRAWAL",
    designation: "Deputy Advocate General",
    ofccont: "",
    mob: "9826723750",
    office: "",
    mobw: "9826723750",
    gmail: "",
  },
  {
    sr: "14",
    name: "SHRI ALOK NIGAM,",
    designation: "Government Advocate",
    ofccont: "",
    mob: "9406135753",
    office: "",
    mobw: "9406135753",
    gmail: "",
  },
  {
    sr: "15",
    name: "SHRI PEEYUSH BHATIA",
    designation: "Government Advocate",
    ofccont: "",
    mob: "9811323884",
    office: "",
    mobw: "9811323884",
    gmail: "",
  },
  {
    sr: "16",
    name: "MADAM SUNITA JAIN",
    designation: "Government Advocate",
    ofccont: "",
    mob: "9827188470",
    office: "",
    mobw: "9827188470",
    gmail: "",
  },
  {
    sr: "17",
    name: "SHRI ADIL MINHAJ",
    designation: "Government Advocate",
    ofccont: "",
    mob: "9425247299",
    office: "",
    mobw: "9425247299",
    gmail: "",
  },
  {
    sr: "18",
    name: "SHRI RAHUL JHA",
    designation: "Government Advocate",
    ofccont: "",
    mob: "8319534369",
    office: "",
    mobw: "8319534369",
    gmail: "",
  },
  {
    sr: "19",
    name: "SHRI VIMLESH BAJPAI",
    designation: "Government Advocate",
    ofccont: "",
    mob: "9827155528",
    office: "",
    mobw: "9827155528",
    gmail: "",
  },
  {
    sr: "20",
    name: "SHRI RAGHVENDRA VERMA",
    designation: "Government Advocate",
    ofccont: "",
    mob: "9826756914",
    office: "",
    mobw: "9826756914",
    gmail: "",
  },
  {
    sr: "21",
    name: "SHRI DEVESH VERMA",
    designation: "Government Advocate",
    ofccont: "",
    mob: "98279-54016",
    office: "",
    mobw: "98279-54016",
    gmail: "",
  },
  {
    sr: "22",
    name: "SHRI RAVISH VERMA",
    designation: "Government Advocate",
    ofccont: "",
    mob: "9826134624",
    office: "",
    mobw: "7999844184",
    gmail: "",
  },
  {
    sr: "23",
    name: "SHRI K.K. SINGH",
    designation: "Government Advocate",
    ofccont: "",
    mob: "9039684452",
    office: "",
    mobw: "9039684452",
    gmail: "",
  },
  {
    sr: "24",
    name: "SHRI GHANSHYAM PATEL",
    designation: "Government Advocate",
    ofccont: "",
    mob: "9827460053",
    office: "",
    mobw: "9827460053",
    gmail: "",
  },
  {
    sr: "25",
    name: "SHRI AYAZ NAVED",
    designation: "Government Advocate",
    ofccont: "",
    mob: "9425208971",
    office: "",
    mobw: "9893236893",
    gmail: "",
  },
  {
    sr: "26",
    name: "SHRI SAMEER SHARMA,",
    designation: "Dy. Government Advocate",
    ofccont: "",
    mob: "9827460286",
    office: "",
    mobw: "9827460286",
    gmail: "",
  },
  {
    sr: "27",
    name: "SHRI GAGAN TIWARI",
    designation: "Dy. Government Advocate",
    ofccont: "",
    mob: "7566992239",
    office: "",
    mobw: "7566992239",
    gmail: "",
  },
  {
    sr: "28",
    name: "SHRI VIKRAM SHARMA,",
    designation: "Dy. Government Advocate",
    ofccont: "",
    mob: "8959002000",
    office: "",
    mobw: "8959002000",
    gmail: "",
  },
  {
    sr: "29",
    name: "MISS AKANKSHA JAIN",
    designation: "Dy. Government Advocate",
    ofccont: "",
    mob: "9407990461",
    office: "",
    mobw: "9407990461",
    gmail: "",
  },
  {
    sr: "30",
    name: "SHRI ANAND VERMA",
    designation: "Dy. Government Advocate",
    ofccont: "",
    mob: "9826938298",
    office: "",
    mobw: "9826938298",
    gmail: "",
  },
  {
    sr: "31",
    name: "SHRI DINESH R.K. TIWARI",
    designation: "Dy. Government Advocate",
    ofccont: "",
    mob: "9993657520",
    office: "",
    mobw: "9993657520",
    gmail: "",
  },
  {
    sr: "32",
    name: "SHRI SUDEEP VERMA",
    designation: "Dy. Government Advocate",
    ofccont: "",
    mob: "9691292988",
    office: "",
    mobw: "9691292988",
    gmail: "",
  },
  {
    sr: "33",
    name: "SHRI SIDDHARTH DUBEY",
    designation: "Dy. Government Advocate",
    ofccont: "",
    mob: "9993161949",
    office: "",
    mobw: "9993161949",
    gmail: "",
  },
  {
    sr: "34",
    name: "SHRI RAVI BHAGAT",
    designation: "Dy. Government Advocate",
    ofccont: "",
    mob: "8959888132",
    office: "",
    mobw: "8959888132",
    gmail: "",
  },
  {
    sr: "35",
    name: "SMT. RICHA SHUKLA",
    designation: "Dy. Government Advocate",
    ofccont: "",
    mob: "9993359680",
    office: "",
    mobw: "9993359680",
    gmail: "",
  },
];
