import React, { Component } from "react";

export class TermsAndConditionPage extends Component {
  render() {
    return (
      <React.Fragment>
        <br />
        <div className='container mx-auto shadow-2xl py-4' id='myP'>
          <h1 className='w-full my-2 p-2 mt-2  text-3xl font-bold leading-tight text-center text-gray-800'>
            Terms And Condition
          </h1>
          <div className='w-full mb-4'>
            <div className='h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t' />
          </div>
          <h2 className='text-2xl px-4 underline'>Terms and Conditions</h2>

          <ul style={{ paddingLeft: 10, paddingRight: 10 }}>
            <li className=' w-full cursor-text  p-2 mx-auto'>
              Contents on this website is published, managed and maintained by
              Office of the Advocate General,Govt. Of Chhattisgarh.
            </li>
            <li className=' w-full cursor-text  p-2 mx-auto'>
              Though all efforts have been made to ensure the accuracy and
              currency of the content on this website, the same should not be
              construed as a statement of law or used for any legal purposes. In
              case of any ambiguity or doubts, users are advised to verify /
              check with the Department(s) and / or other source(s), and to
              obtain appropriate professional advice.
            </li>
            <li className=' w-full cursor-text  p-2  mx-auto'>
              Under no circumstances will this Department be liable for any
              expense, loss or damage including, without limitation, indirect or
              consequential loss or damage, or any expense, loss or damage
              whatsoever arising from use, or loss of use, of data, arising out
              of or in connection with the use of this website.
            </li>
            <li className=' w-full cursor-text  p-2  mx-auto'>
              These terms and conditions shall be governed by and constructed in
              accordance with the Indian Laws. Any dispute arising under these
              terms and conditions shall be subject to the jurisdiction of the
              courts of India.
            </li>
            <li className=' w-full cursor-text  p-2  mx-auto'>
              The information posted on this website could include hypertext
              links or pointers to information created and maintained by
              non-Government / private organizations. Office of the Advocate
              General,Govt. Of Chhattisgarh is providing these links and
              pointers solely for your information and convenience. When you
              select a link to an outside website, you are leaving the Office of
              the Advocate General,Govt. Of Chhattisgarh website and are subject
              to the privacy and security policies of the owners / sponsors of
              the outside website. Office of the Advocate General,Govt. Of
              Chhattisgarh Organization, does not guarantee the availability of
              such linked pages at all times.
            </li>
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

export default TermsAndConditionPage;
