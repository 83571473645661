import React from "react";
import getDatabase from "../../firebase";
import { NotificationManager } from "react-notifications";

const db = getDatabase();

class AddGalleryImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      description: "",
      sequence: null,
      result: null,
      gallery: [],
    };
  }

  componentDidMount() {
    const myWidget = window.cloudinary.createUploadWidget(
      {
        cloudName:
          process.env.NODE_ENV === "development" ? "msahu2595" : "dcr2pfgxy",
        uploadPreset:
          process.env.NODE_ENV === "development" ? "e3rej1yz" : "mfowcjdr",
      },
      (error, result) => {
        if (!error && result && result.event === "success") {
          if (result.info) {
            console.log(result.info);
            NotificationManager.success("Uploaded Successfully.", "Success");
            // this.setState({
            //   original: result.info.url,
            //   thumbnail: result.info.thumbnail_url
            // });
            this.setState({
              result: result.info,
            });
          } else {
            NotificationManager.error("Something Went Wrong.");
          }
        }
      }
    );

    document.getElementById("upload_image_widget").addEventListener(
      "click",
      function () {
        myWidget.open();
      },
      false
    );

    db.collection("gallery")
      .get()
      .then((querySnapshot) => {
        let addimagegal = [];
        querySnapshot.forEach((doc) => {
          const mydocument = doc.data();
          mydocument.id = doc.id;
          addimagegal.push(mydocument);
        });
        this.setState({ gallery: addimagegal });
      });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { description, result } = this.state;

    if (description === "") {
      NotificationManager.warning("Enter Image Description");
      return;
    }
    if (result === null) {
      NotificationManager.warning("Please Upload Image");
      return false;
    }
    const original = result.url;
    const thumbnail = result.thumbnail_url;
    db.collection("gallery")
      .add({ original, description, thumbnail })
      .then((docRef) => {
        if (docRef.id) {
          NotificationManager.success("Submited Successfully", "Success");
          const addimagegal = this.state.gallery;
          addimagegal.unshift({
            id: docRef.id,
            description,
            original,
            thumbnail,
          });
          this.setState({ gallery: addimagegal });
          this.setState({
            description: "",
            result: null,
          });
        } else {
          NotificationManager.error("Something Went Wrong");
        }
      })
      .catch((error) => {
        console.error("Error Adding Document: ", error);
      });
  };

  handleChange = (e) => this.setState({ [e.target.name]: e.target.value });

  handleDelete = (id) => {
    console.log(id);
    db.collection("gallery")
      .doc(id)
      .delete()
      .then(() => {
        NotificationManager.success(
          "Document Successfully Deleted!",
          "Success"
        );
        const images = this.state.gallery;
        const myLatestImages = images.filter((s) => s.id !== id);
        this.setState({ gallery: myLatestImages });
      })
      .catch(function (error) {
        console.error("Error Removing Document: ", error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <form
          className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
          onSubmit={this.handleSubmit}
        >
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Image Description:
            </label>
            <input
              className="shadow appearance-none border  rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight 
            focus:outline-none focus:shadow-outline"
              type="text"
              name="description"
              value={this.state.description}
              onChange={this.handleChange}
            />
          </div>

          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="grid-last-name"
            >
              Upload Image
            </label>
            <button
              type="button"
              id="upload_image_widget"
              className="cloudinary-button"
            >
              Upload files
            </button>
          </div>

          <div className="mb-4">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
              type="submit"
            >
              Add Image
            </button>
          </div>
        </form>
        <div className="bg-blue-800 p-2 shadow text-xl text-white">
          <h3 className="font-bold pl-2">GALLERY IMAGE LISTINGS</h3>
        </div>
        {this.state.gallery.map((image, i) => {
          return (
            <div
              key={i}
              className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
            >
              <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2 capitalize">
                  Image Description : {image.description}
                </div>
              </div>
              <span className="px-6 py-4">
                <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2">
                  Image Link :
                  <a
                    href={image.original}
                    style={{
                      fontFamily: "Arial",
                      color: "red",
                      textDecoration: "underline",
                    }}
                  >
                    Click to See Image.
                  </a>
                </span>
              </span>
              <div className="px-6 py-4">
                <button
                  name="deleteButton"
                  type="button"
                  onClick={() => this.handleDelete(image.id)}
                  className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                >
                  Delete
                </button>
              </div>
            </div>
          );
        })}
      </React.Fragment>
    );
  }
}

export default AddGalleryImage;
