import React from "react";
import getfirebase from "../../firebase.js";
import { NotificationManager } from "react-notifications";

const db = getfirebase();

class AddAgProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      heading: "",
      designation: "",
      result: null,
      image: "",
      twitterusername: "",
      fbpageurl: "",
      gallery: [],
      fullname: "",
    };
  }

  async componentDidMount() {
    let agData = null;
    const querySnapshot = await db.collection("agprofile").get();
    querySnapshot.forEach((doc) => {
      agData = { id: doc.id, ...doc.data() };
    });
    console.log(agData);
    if (agData !== null)
      this.setState({
        ...agData,
      });
    const myWidget = window.cloudinary.createUploadWidget(
      {
        cloudName: process.env.NODE_ENV === "development" ? "relive-cloud" : "relive-cloud",
        uploadPreset: process.env.NODE_ENV === "development" ? "vw631vrf" : "vw631vrf",
      },
      (error, result) => {
        if (!error && result && result.event === "success") {
          if (result.info) {
            console.log(result.info);
            NotificationManager.success("Uploaded Successfully.", "Success");
            console.log(result);
            this.setState({
              result: result.info,
              image: result.info.url,
            });
          } else {
            NotificationManager.error("Something Went Wrong.");
          }
        }
      }
    );

    document.getElementById("upload_image_widget").addEventListener(
      "click",
      function () {
        myWidget.open();
      },
      false
    );
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const { heading, designation, twitterusername, fbpageurl, fullname, image } = this.state;
    if (heading === "") {
      NotificationManager.warning("Enter heading");
      return;
    }
    if (designation === "") {
      NotificationManager.warning("Enter Description");
      return;
    }
    if (image === "") {
      NotificationManager.warning("Please Upload Image");
      return false;
    }
    if (twitterusername === null) {
      NotificationManager.warning("Please enter twitter username");
      return false;
    }
    if (fbpageurl === null) {
      NotificationManager.warning("Please enter twitter username");
      return false;
    }
    if (fullname === null) {
      NotificationManager.warning("Please enter Full name");
      return false;
    }

    db.collection("agprofile")
      .doc("SHujA8VWCxUkGDiZKhdo")
      .set({
        heading: heading,
        designation: designation,
        image: image,
        twitterusername: twitterusername,
        fbpageurl: fbpageurl,
        fullname: fullname,
      })
      .then(() => {
        NotificationManager.success("Submited Successfully", "Success");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    const { heading, designation, twitterusername, fbpageurl, fullname, image } = this.state;
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="container px-8 py-8 mx-auto">
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                Full name
              </label>
              <input
                name="fullname"
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-password"
                type="text"
                placeholder="Enter FullName"
                onChange={this.handleChange}
                value={fullname}
              />
            </div>
            <div className="w-full px-3">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                Designation
              </label>
              <input
                name="heading"
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-password"
                type="text"
                placeholder="Enter Name of officers"
                onChange={this.handleChange}
                value={heading}
              />
            </div>

            <div className="w-full px-3">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                Description
              </label>
              <textarea
                rows="4"
                cols="50"
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-password"
                type="text"
                placeholder="Enter Description"
                name="designation"
                onChange={this.handleChange}
                value={designation}
              />
            </div>

            <div className="w-full px-3">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                Twitter Username
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-password"
                type="text"
                placeholder="Enter Username"
                name="twitterusername"
                onChange={this.handleChange}
                value={twitterusername}
              />
            </div>

            <div className="w-full px-3">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                facebook page url
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-password"
                type="text"
                placeholder="Enter Facebook page url"
                name="fbpageurl"
                onChange={this.handleChange}
                value={fbpageurl}
              />
            </div>

            <div className="w-full px-3">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="grid-last-name">
                Upload Image
              </label>
              <button type="button" id="upload_image_widget" className="cloudinary-button">
                Upload
              </button>
              <div className="ml-5 mt-5 thumbnail">
                <img src={`${image}`} alt="image_profile" style={{ width: 200, border: "5px solid #e3e3e3" }} />
              </div>
            </div>
          </div>
        </div>

        <div
          className="mt-4"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button className="hover:bg-blue-dark text-white font-bold py-2 px-4 rounded-full" style={{ background: "blue", textAlign: "center" }}>
            Submit
          </button>
        </div>
      </form>
    );
  }
}

export default AddAgProfile;
