import React, { Component } from 'react';

export class ContactUsPage extends Component {
  render() {
    return (
      <section className='bg-white border-b py-8' style={{ marginTop: 50 }}>
        <div className='container mx-auto flex flex-wrap pt-4 pb-12' id='myP'>
          <h1 className='w-full my-2 text-2xl font-bold leading-tight text-center text-gray-800'>
            Contact Us
          </h1>
          <div className='w-full mb-4'>
            <div className='h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t' />
          </div>
          <div>
            <div className='flex flex-col'>
              <p className='text-lg font-bold' style={{ marginTop: 50 }}>
                Office Of The Advocate General Contact
              </p>
              <p>Email ID: agcghc.cg@nic.in</p>
              <p>Phone No. 07752 -241302, 242303</p>
              <a
                href='https://docs.google.com/forms/d/e/1FAIpQLSdN8SPG-l-1AGaqcH59VmjdsaXJRndMimQgNALMTvWMTB2lyA/viewform'
                style={{ width: 150 }}
                className='mx-auto gradient lg:mx-0 hover:underline bg-black text-gray-800 font-bold rounded-full my-6 py-4 px-8 shadow-lg'
              >
                Contact Us
              </a>
            </div>

            <div className='flex flex-col'>
              <p className='text-lg font-bold' style={{ marginTop: 50 }}>
                Interested to work with us!
              </p>
              <p>Send your details and we will get in touch.</p>
              <a
                href='https://docs.google.com/forms/d/e/1FAIpQLSdVFtOkYb7INa0fACk-ZejY1fKQUsFXIb74C_UGuK-qZ7GUHA/viewform'
                style={{ width: 150 }}
                className='mx-auto gradient lg:mx-0 hover:underline bg-black text-gray-800 font-bold rounded-full my-6 py-4 px-8 shadow-lg'
              >
                Apply
              </a>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ContactUsPage;
