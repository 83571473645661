import React, { Component } from "react";
import ImageGallery from "react-image-gallery";
import "../../node_modules/react-image-gallery/styles/css/image-gallery.css";
import getDatabase from "../firebase";
const db = getDatabase();

export class PhotoGalleryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gallery: []
    };
  }

  componentDidMount() {
    db.collection("gallery")
      .get()
      .then(querySnapshot => {
        let showimagegal = [];
        querySnapshot.forEach(doc => {
          const mydocumnet = doc.data();
          mydocumnet.id = doc.id;
          showimagegal.push(mydocumnet);
        });
        this.setState({ gallery: showimagegal });
      });
  }

  render() {
    return (
      <div className="container mx-auto px-4 py-4">
        <ImageGallery items={this.state.gallery} lazyLoad={false} />
      </div>
    );
    // const images = [
    //   {
    //     original:
    //       "https://res.cloudinary.com/dcr2pfgxy/image/upload/v1561527722/ag-portal/karyalay.jpg",
    //     thumbnail:
    //       "https://res.cloudinary.com/dcr2pfgxy/image/upload/v1561527722/ag-portal/karyalay.jpg",
    //     description: "Advocate General office, High Court Premise, Chhattisgarh"
    //   },
    //   {
    //     original:
    //       "https://res.cloudinary.com/dcr2pfgxy/image/upload/v1561528109/ag-portal/65457471_2391759494434509_1579177732921622528_n.jpg.jpg",
    //     thumbnail:
    //       "https://res.cloudinary.com/dcr2pfgxy/image/upload/v1561528109/ag-portal/65457471_2391759494434509_1579177732921622528_n.jpg.jpg",
    //     description: "Chhattisgarh High Court, Bilaspur."
    //   },
    //   {
    //     original:
    //       "https://res.cloudinary.com/dcr2pfgxy/image/upload/v1561532614/ag-portal/65220327_576897242838159_7987150208477691904_n.jpg.jpg",
    //     thumbnail:
    //       "https://res.cloudinary.com/dcr2pfgxy/image/upload/v1561532614/ag-portal/65220327_576897242838159_7987150208477691904_n.jpg.jpg",
    //     description: `Hon'ble AG Shri Satish Verma along with his team of legal officers, panel advocates and bar association at a plantation drive at arpa coast, bilaspur.`
    //   },
    //   {
    //     original:
    //       "https://res.cloudinary.com/dcr2pfgxy/image/upload/v1561532628/ag-portal/65385802_1440609802746331_3636825478431506432_n.jpg.jpg",
    //     thumbnail:
    //       "https://res.cloudinary.com/dcr2pfgxy/image/upload/v1561532628/ag-portal/65385802_1440609802746331_3636825478431506432_n.jpg.jpg",
    //     description: `Hon'ble AG Shri Satish Verma along with his team of legal officers, panel advocates and bar association at a plantation drive at arpa coast, bilaspur.`
    //   },
    //   {
    //     original:
    //       "https://res.cloudinary.com/dcr2pfgxy/image/upload/v1561532641/ag-portal/65301381_740865019661320_660960029079240704_n.jpg.jpg",
    //     thumbnail:
    //       "https://res.cloudinary.com/dcr2pfgxy/image/upload/v1561532641/ag-portal/65301381_740865019661320_660960029079240704_n.jpg.jpg",
    //     description: `Hon'ble AG Shri Satish Verma along with his team of legal officers, panel advocates and bar association at a plantation drive at arpa coast, bilaspur.`
    //   }
    // ];
  }
}

export default PhotoGalleryPage;
