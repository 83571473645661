import React, { Component } from "react";

export class InformationForIOCPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <section className="bg-white border-b py-8">
        <div className="container mx-auto flex flex-wrap pt-4 pb-12" id='myP'>
          <h1 className="w-full my-2 text-2xl font-bold leading-tight text-center text-gray-800">
            Information For O.I.C.
          </h1>
          <div className="w-full mb-4">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t" />
          </div>
          <ul className="bg-gray-200 flex flex-1 flex-col">
            <li className=" w-full  flex items-center p-2 bg-gray-200 mx-auto">
              <Dot /> Important Procedure to be followed by O.I.C.
            </li>
            <li className=" w-full flex items-center  p-2 bg-gray-200 mx-auto">
              <Dot /> Procedure to be followed for filling returns
            </li>
            <li className=" w-full p-2 bg-gray-200 mx-auto">
              <div className="flex items-center ">
                <Dot /> List of cases in which the OICs have filled the returns
                up to the certain date
              </div>
              <li className=" w-full px-10 bg-gray-200 mx-auto">
                <div className="flex items-center">
                  <Dot /> List of Writ petitions (Other than Service matters),
                  in which reply not filed of year 2018 & 2019
                </div>
                <li className=" w-full underline  flex items-center  px-10 bg-gray-200 mx-auto">
                  <Dot />
                  <a href="https://res.cloudinary.com/dcr2pfgxy/image/upload/v1561832783/ag-portal/files/1.pdf">
                    Department of Commercial Tax.
                  </a>
                </li>
                <li className=" w-full underline  flex items-center  px-10 bg-gray-200 mx-auto">
                  <Dot />
                  <a href="https://res.cloudinary.com/dcr2pfgxy/image/upload/v1561832781/ag-portal/files/2.pdf">
                    Department of Panchayat.
                  </a>
                </li>
                <li className=" w-full underline  flex items-center  px-10 bg-gray-200 mx-auto">
                  <Dot />
                  <a href="https://res.cloudinary.com/dcr2pfgxy/image/upload/v1561832781/ag-portal/files/3.pdf">
                    Department of Tribal
                  </a>
                </li>
                <li className=" w-full underline  flex items-center  px-10 bg-gray-200 mx-auto">
                  <Dot />
                  <a href="https://res.cloudinary.com/dcr2pfgxy/image/upload/v1561832781/ag-portal/files/4.pdf">
                    Department of Urban Admin
                  </a>
                </li>
                <li className=" w-full underline  flex items-center  px-10 bg-gray-200 mx-auto">
                  <Dot />
                  <a href="https://res.cloudinary.com/dcr2pfgxy/image/upload/v1561832782/ag-portal/files/5.pdf">
                    Department of Home 2018
                  </a>
                </li>
                <li className=" w-full underline  flex items-center  px-10 bg-gray-200 mx-auto">
                  <Dot />
                  <a href="https://res.cloudinary.com/dcr2pfgxy/image/upload/v1561832784/ag-portal/files/6.pdf">
                    Department of Home 2019
                  </a>
                </li>
                <li className=" w-full underline  flex items-center  px-10 bg-gray-200 mx-auto">
                  <Dot />
                  <a href="https://res.cloudinary.com/dcr2pfgxy/image/upload/v1561832785/ag-portal/files/7.pdf">
                    Department of Revenue 2018
                  </a>
                </li>
                <li className=" w-full underline  flex items-center  px-10 bg-gray-200 mx-auto">
                  <Dot />
                  <a href="https://res.cloudinary.com/dcr2pfgxy/image/upload/v1561832786/ag-portal/files/8.pdf">
                    Department of Revenue 2019
                  </a>
                </li>
              </li>
            </li>
            <li className=" w-full underline  px-10 bg-gray-200 mx-auto">
              <div className="flex items-center">
                <Dot /> List of cases (Service Matter) in which return has not
                been filed till 01/06/2019
              </div>
              <li className=" w-full underline  flex items-center  px-10 bg-gray-200 mx-auto">
                <Dot />
                <a href="https://res.cloudinary.com/dcr2pfgxy/image/upload/v1561834093/ag-portal/files/important_procedure.jpg">
                  Service Matter
                </a>
              </li>
            </li>
            <li className=" w-full flex items-center p-2 bg-gray-200 mx-auto">
              <Dot /> Contact numbers of Officials of Government of Chhattisgarh
              (Departmentwise)
            </li>
            <li className=" w-full flex items-center p-2 bg-gray-200 mx-auto">
              <Dot /> Departmentwise list of pending cases
            </li>
            <li className=" w-full flex items-center  p-2 bg-gray-200 mx-auto">
              <Dot /> List of cases in which Oficcers have to apear personally
            </li>
          </ul>
        </div>
      </section>
    );
  }
}

export default InformationForIOCPage;

export const Dot = (props) => {
  if (props.number) {
    return(
      <div
      style={{
        margin: 5
      }}
    >{props.number}. </div>
    )
  }
  return (
    <div
      style={{
        width: 10,
        height: 10,
        borderRadius: "50%",
        backgroundColor: "black",
        margin: 5
      }}
    />
  );
};
