/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import getfirebase from "../firebase.js";

const db = getfirebase();

export class AgProfilePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agprofiledata: null,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);

    db.collection("agprofile")
      .doc("SHujA8VWCxUkGDiZKhdo")
      .get()
      .then((doc) => {
        console.log(doc.data());
        this.setState({
          agprofiledata: doc.data(),
        });
      })
      .catch((error) => console.log(error));
  }
  render() {
    const { agprofiledata } = this.state;
    if (agprofiledata === null) {
      return <div>Loading ...</div>;
    }
    return (
      <section className="bg-white border-b py-8 ">
        <div
          className="container mx-auto flex flex-wrap pt-4 pb-12 shadow-2xl bg-gray-200"
          id="myP"
        >
          <h1 className="w-full my-2 text-2xl font-bold leading-tight text-center text-gray-800">
            {agprofiledata.heading}
          </h1>
          <div className="flex flex-wrap">
            <div
              className="flex-1 text-gray-700 text-center  px-4 py-2 m-2"
              style={{ minWidth: 300, textAlign: "justify" }}
            >
              {agprofiledata.designation}
            </div>
            <div
              className="flex-1 text-gray-700 text-center  px-4 py-2 m-2"
              style={{ minWidth: 300 }}
            >
              <img
                style={{ width: "100%", height: 400 }}
                src={`${agprofiledata.image}`}
                // src="https://res.cloudinary.com/dcr2pfgxy/image/upload/v1561527713/ag-portal/ag2.jpg"
              />
              <p
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                {agprofiledata.fullname}
              </p>
            </div>
          </div>

          <div className="flex bg-gray-200 flex-wrap justify-around mx-auto mt-5">
            <TwitterTimelineEmbed
              sourceType="profile"
              screenName={agprofiledata.twitterusername}
              options={{ height: 400, width: 300 }}
            />
            <div className="ml-10">
              <iframe
                src={agprofiledata.fbpageurl}
                // src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FSatish-Chandra-Verma-620059825071122%2F&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                width={340}
                height={400}
                style={{ border: "none", overflow: "hidden" }}
                scrolling="no"
                frameBorder={0}
                allowtransparency="true"
                allow="encrypted-media"
              />
            </div>
          </div>
        </div>
        {/* <div className="flex bg-gray-200 flex-wrap justify-around ">
          <TwitterTimelineEmbed
            sourceType="profile"
            screenName={ag.twitterusername}
            options={{ height: 400, width: 300 }}
          /> */}
      </section>
    );
  }
}

export default AgProfilePage;
