import React, { Component } from "react";

export class JudgementAndOrderPage extends Component {
  render() {
    return (
      <section className="bg-white border-b">
        <div className="container mx-auto flex flex-wrap pt-4 pb-12" id="myP">
          <h1 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
            Judgement And Orders
          </h1>
          <div className="w-full mb-4">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t" />

            <ol style={{ fontSize: 20 }}>
              <li>
                <a href="http://highcourt.cg.gov.in/hcbspjudgement/query/jud_status.php?chopt=J">
                  1. Judgement
                </a>
              </li>
              <li>
                <a href="http://highcourt.cg.gov.in/hcbspjudgement/query/jud_status.php?chopt=O">
                  2. Orders
                </a>
              </li>
            </ol>
          </div>
        </div>
      </section>
    );
  }
}

export default JudgementAndOrderPage;
