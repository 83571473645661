import React, { Component } from "react";
import { Dot } from "../pages/InformationForIOCPage";
import getfirebase from "../firebase";

const db = getfirebase();

export class NotificationPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventListings: []
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    db.collection("announcement_event")
    .get()
    .then(querySnapshot => {
      let emptyTenderlist = [];
      querySnapshot.forEach(doc => {
        const mydocument = doc.data();
        mydocument.id = doc.id;
        emptyTenderlist.push(mydocument);
      });
      this.setState({ eventListings: emptyTenderlist });
    });
  }

  render() {
    return (
      <section className='bg-white border-b'>
        <div className='container mx-auto flex flex-wrap pt-4 pb-12'>
          <h1 className='w-full my-2 text-2xl font-bold leading-tight text-center text-gray-800'>
            Notification / Announcements
          </h1>
          <div className='w-full mb-4'>
            <div className='h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t' />
          </div>

          <table className='text-left w-full'>
            <thead className='bg-black flex text-white w-full'>
              <tr className='flex w-full mb-4'>
                <th className='p-4 w-1/4'>Sr.No.</th>
                <th className='p-4 w-1/4'>Event</th>
                <th className='p-4 w-1/4'>Date</th>
                <th className='p-4 w-1/4'>Validity</th>
              </tr>
            </thead>

            <tbody className='flex flex-col items-center justify-between overflow-y-scroll w-full'>
              {this.state.eventListings.map((events, i) => {
                return (
                  <tr className='flex w-full mb-4  items-center' key={i}>
                    <td className='p-4 w-1/4'><Dot number={i + 1} /></td>
                    <td className='p-4 w-1/4'><a href={`${events.link}`}>{events.event}</a></td>
                    <td className='p-4 w-1/4'>{events.date}</td>
                    <td className='p-4 w-1/4'>{events.validity}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </section>
    );
  }
}

export default NotificationPage;

// const notification = [
//   {
//     sr: 1,
//     name: `Inaugration ceremony of newly constructed floors, offices and web portal of advocate general office in the presence of Hon'ble Chief Minister, Chhattisgarh and Hon'ble Chief Justice, High Court of Chhattisgarh.`,
//     date: "30th june, 11AM Onwards",
//     validity: "----"
//   }
// ];
