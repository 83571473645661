/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { NotificationManager } from 'react-notifications';
import { withRouter } from 'react-router-dom';

export class LoginPage extends React.Component {
	constructor() {
		super();
		this.state = {
			username: '',
			password: ''
		};
	}

	handleChange = (e) => this.setState({ [e.target.name]: e.target.value });

	handleSubmit = (e) => {
		e.preventDefault();
		const { username, password } = this.state;
		if (username === '') {
			NotificationManager.warning('Please enter Username');
			return;
		}

		if (password === '') {
			NotificationManager.warning('Please enter Password');
			return;
		}
		if (username.trim() === 'cgadvocate' && password.trim() === 'cgadvocate') {
			localStorage.setItem('username', username);
			localStorage.setItem('password', password);
			this.props.history.push('/dashboard');
		} else {
			NotificationManager.error('Invalid Credentials');
			this.setState({ username: '', password: '' });
		}
	};

	render() {
		return (
			<div className="container mx-auto" style={{ maxWidth: '500px', marginTop: '200px', padding: '10px' }}>
				<form onSubmit={this.handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
					<div className="mb-4">
						<label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
							Username
						</label>
						<input
							className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
							id="username"
							type="text"
							value={this.state.username}
							onChange={this.handleChange}
							name="username"
							placeholder="Username"
						/>
					</div>
					<div className="mb-6">
						<label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
							Password
						</label>
						<input
							className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
							id="password"
							type="password"
							value={this.state.password}
							onChange={this.handleChange}
							name="password"
							placeholder="******************"
						/>
					</div>
					<div className="flex items-center justify-between">
						<button
							className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
							type="submit"
						>
							Sign In
						</button>
						<a
							className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
							href="#"
						>
							Forgot Password?
						</a>
					</div>
				</form>
			</div>
		);
	}
}

export default withRouter(LoginPage);
