import React from 'react';
import { withRouter } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';

export default (MyComponent) => {
	class AuthChecker extends React.Component {
		constructor(props) {
			super(props);
			this.state = {
				isLoggedIn: false
			};
		}
		componentDidMount() {
			this.authCheck();
		}

		authCheck = () => {
			const username = localStorage.getItem('username');
			const password = localStorage.getItem('password');
			if (username === null || password === null) {
				NotificationManager.error('Please Login ');
				this.props.history.push('/login');
			} else {
				if (username === 'cgadvocate' && password === 'cgadvocate') {
					console.log(username);
					console.log(password);
					this.setState({ isLoggedIn: true });
				} else {
					NotificationManager.error('Invalid Credentials');
					this.props.history.push('/login');
				}
			}
		};

		render() {
			if (!this.state.isLoggedIn) {
				return null;
			}
			console.log(this.props);
			return <MyComponent {...this.props} />;
		}
	}

	return withRouter(AuthChecker);
};
