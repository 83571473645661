/* eslint-disable no-unused-vars */
import React, { Component } from 'react';

export class EmpanelledAdvocatesPage extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		return (
			<section className="bg-white border-b ">
				<div className="container mx-auto flex flex-wrap pt-4 pb-12" id="myP">
					<h1 className="w-full my-2 text-2xl font-bold leading-tight text-center text-gray-800">
						Empanelled Advocates
					</h1>
					<div className="w-full mb-4">
						<div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t" />
					</div>
					<table className="text-left w-full">
						<thead className="bg-black flex text-white w-full">
							<tr className="flex w-full mb-4">
								<th className="p-4 w-1/4">Sr.No.</th>
								<th className="p-4 w-1/4">Name Of Panel Advocate</th>
								<th className="p-4 w-1/4">Mobile Number</th>
							</tr>
						</thead>
						{/* Remove the nasty inline CSS fixed height on production and replace it with a CSS class — this is just for demonstration purposes! */}
						<tbody className="flex flex-col items-center justify-between overflow-y-scroll w-full">
							{Advocates.map((ag, i) => {
								return (
									<tr className="flex w-full mb-4  items-center" key={ag.sr}>
										<td className="p-4 w-1/4">{ag.sr}</td>
										<td className="p-4 w-1/4">{ag.name}</td>
										<td className="p-4 w-1/4">{ag.mob === '' ? '---' : ag.mob}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			</section>
		);
	}
}

export default EmpanelledAdvocatesPage;

const Advocates = [
	{
		sr: '1',
		name: 'Shri Nitansh Jaiswal',
		mob: '99079-25562'
	},
	{
		sr: '2',
		name: 'Shri Shailendra Sharma',
		mob: '99938-64791'
	},
	{
		sr: '3',
		name: 'Md. Ruhul Ameen Memon',
		mob: '87701-37524'
	},
	{
		sr: '4',
		name: 'Mr Abhishek Singh',
		mob: '9669448383'
	},
	{
		sr: '5',
		name: 'Shri Lav Sharma',
		mob: '94241-47424'
	},
	{
		sr: '6',
		name: 'Shri Deepak Kumar Singh',
		mob: '94252-27898'
	},
	{
		sr: '7',
		name: 'Shri Ashutosh Shukla',
		mob: '90980-60995'
	},
	{
		sr: '8',
		name: 'Miss Monika Singh Thakur	',
		mob: '77718-11511'
	},
	{
		sr: '9',
		name: 'Shri Rishabh Chandra Singh Deo',
		mob: '79996-30497'
	},
	{
		sr: '10',
		name: 'Smt Shubha Shrivastava',
		mob: '93492-01130'
	},
	{
		sr: '11',
		name: 'Shri Amit Verma',
		mob: '98271-11059'
	},
	{
		sr: '12',
		name: 'Smt Smriti Shrivastava',
		mob: '93025-81973'
	},
	{
		sr: '13',
		name: 'Smt Prabha Sharma',
		mob: '74158-54573'
	},
	{
		sr: '14',
		name: 'Smt Nand Kumari Kashyap',
		mob: '982259-00482'
	},
	{
		sr: '15',
		name: 'Miss Shakshi Goyal',
		mob: '79874-14113'
	},
	{
		sr: '16',
		name: 'Akansha Verma',
		mob: '8085255535'
	},
	{
		sr: '17',
		name: 'Shri U P S Sahu',
		mob: '78288-15659'
	},
	{
		sr: '18',
		name: 'Shri Surendra Dewangan',
		mob: '93296-33946'
	},
	{
		sr: '19',
		name: 'Shri Shailesh Kumar Puriya',
		mob: '8103797277'
	},
	{
		sr: '20',
		name: 'Shri Afroj Khan',
		mob: '9826167081'
	},
	{
		sr: '21',
		name: 'Miss Laxmin Kashyap',
		mob: '7722913810'
	},
	{
		sr: '22',
		name: 'Shri Soumitra Kesharwani',
		mob: '8827858000'
	},
	{
		sr: '23',
		name: 'Shri Shreyansh Agrawal',
		mob: '9977882422'
	},
	{
		sr: '24',
		name: 'Shri Malay Jain',
		mob: '9200002567'
	},
	{
		sr: '25',
		name: 'Shri Vivek Mishra',
		mob: '8871610704'
	},
	{
		sr: '26',
		name: 'Shri Topilal Bareth',
		mob: '9691480221'
	},
	{
		sr: '27',
		name: 'Shri H A P S Bhatia',
		mob: '8817486881'
	},
	{
		sr: '28',
		name: 'Shri Pratik Tiwari',
		mob: '8269836660'
	},
	{
		sr: '29',
		name: 'Shri Rishabh Bisen',
		mob: '9098979630'
	},
	{
		sr: '30',
		name: 'Shri Sharad Mishra',
		mob: '9907463525'
	},
	{
		sr: '31',
		name: 'Miss Nupoor Sonkar',
		mob: '7067133432'
	},
	{
		sr: '32',
		name: 'Smt Smita Jha',
		mob: '8109104068'
	},
	{
		sr: '33',
		name: 'Miss Vaishali Mahilong',
		mob: '9174112200'
	},
	{
		sr: '34',
		name: 'Shri Ritesh Giri',
		mob: '8319541520'
	},
	{
		sr: '35',
		name: 'Shri Sacchidanand Yadav',
		mob: '8319521090'
	},
	{
		sr: '36',
		name: 'Shri Sabyasachi Choubey',
		mob: '9300643433'
	},
	{
		sr: '37',
		name: 'Shri Aman Tamboli',
		mob: '7987747647'
	},
	{
		sr: '38',
		name: 'Shri Treveenishankar Sahu',
		mob: '8962414327'
	},
	{
		sr: '39',
		name: 'Shri Sanjeev Kumar Agarwal',
		mob: '9893706372'
	},
	{
		sr: '40',
		name: 'Shri Sakib Ahmed',
		mob: '9691967344'
	},
	{
		sr: '41',
		name: 'Shri Hariom Rai',
		mob: '9131818631'
	},
	{
		sr: '42',
		name: 'Shri Shubham Bajpai',
		mob: '9407695197'
	},
	{
		sr: '43',
		name: 'Shri Shrikant Kaushik',
		mob: '9406009138'
	},
	{
		sr: '44',
		name: 'Smt. Mukta Tripathi',
		mob: '9039340024'
	},
	{
		sr: '45',
		name: 'Shri Khulesh Sahu',
		mob: '8962858810'
	},
	{
		sr: '46',
		name: 'Smt Priya Sharma',
		mob: '8225996930'
	},
	{
		sr: '47',
		name: 'Shri Ajay Kumrani',
		mob: '7000595881'
	},
	{
		sr: '48',
		name: 'Miss Anuradha Jain',
		mob: '9425214660'
	},
	{
		sr: '49',
		name: 'Shri Vaibhav K. Agarwal',
		mob: '9406342207'
	},
	{
		sr: '50',
		name: 'Shri Vivek Sharma',
		mob: '8770496240'
	},
	{
		sr: '51',
		name: 'Shri Kalpesh Ruprel',
		mob: '9028308000'
	},
	{
		sr: '52',
		name: 'Smt Sunita Sahu',
		mob: '9111099302'
	},
	{
		sr: '53',
		name: 'Shri Devesh G Kela',
		mob: '7879596720'
	},
	{
		sr: '54',
		name: 'Smt. Mandwi  Bhardwaj',
		mob: '7747807387'
	},
	{
		sr: '55',
		name: 'Miss Pushplata Khalkho	',
		mob: '8839968518'
	},
	{
		sr: '56',
		name: 'Shri G L Uike',
		mob: '9907417213'
	},
	{
		sr: '57',
		name: 'Smt Neeta Tulsani Thawani',
		mob: '9111241224'
	},
	{
		sr: '58',
		name: 'Smt. Anuja Sharma',
		mob: '8319552215'
	},
	{
		sr: '59',
		name: 'Shri Anurag Tripathi',
		mob: '7772071680'
	},
	{
		sr: '60',
		name: 'Shri Tarkeshwar Nande',
		mob: '9993232558'
	},
	{
		sr: '61',
		name: 'Smt. Binu Sharma',
		mob: '9827492623'
	},
	{
		sr: '62',
		name: 'Smt Ankita Shukla',
		mob: '7987075282'
	},
	{
		sr: '63',
		name: 'Miss Avelin Juneja Gambhir',
		mob: '7828070222'
	},
	{
		sr: '64',
		name: 'Miss Saumya Sharma',
		mob: '9179682788'
	},
	{
		sr: '65',
		name: 'Shri Prateek Singh Thakur',
		mob: '8319515813'
	},
	{
		sr: '66',
		name: 'Shri Anmol Sharma',
		mob: '9589388000'
	},
	{
		sr: '67',
		name: 'Smt M. Asha',
		mob: '9691166173'
	},
	{
		sr: '68',
		name: 'Shri Swajeet S Ubeja',
		mob: '8717888881'
	},
	{
		sr: '69',
		name: 'Shri Bharat Kumar Gulbani',
		mob: '8319740057'
	},
	{
		sr: '70',
		name: 'Miss Raashi Tiwari',
		mob: '9770558592'
	},
	{
		sr: '71',
		name: 'Shri Amit Buxy',
		mob: '9691411665'
	},
	{
		sr: '72',
		name: 'Shri Amandeep Singh',
		mob: '9691111534'
	},
	{
		sr: '73',
		name: 'Shri Kanwaljeet Singh Saini',
		mob: '7987208953'
	},
	{
		sr: '74',
		name: 'Miss Archi Agrawal',
		mob: '9131409851'
	},
	{
		sr: '75',
		name: 'Miss Isha Jajodiya',
		mob: '9039227589'
	},
	{
		sr: '76',
		name: 'Shri Vedant Shadangi',
		mob: '7987776936'
	},
	{
		sr: '77',
		name: 'Shri Pranjal Shukla',
		mob: '8087297811'
	},
	{
		sr: '78',
		name: 'Miss Nupur Trivevdi',
		mob: '9589132882'
	},
	{
		sr: '79',
		name: 'Shri Dashrath Lal Prajapati',
		mob: '8817393174'
	},
	{
		sr: '80',
		name: 'Shri Pankaj Singh',
		mob: '9599300640'
	},
	{
		sr: '81',
		name: 'Shri Soumya Rai',
		mob: '9775299222'
	},
	{
		sr: '82',
		name: 'Poorva Tiwari ',
		mob: '9691882441'
	},
	{
		sr: '83',
		name: 'Shreyansh Mehta',
		mob: '7974664189'
	},
	{
		sr: '84',
		name: 'Shri Santosh Bharat',
		mob: '9827282289'
	},

	{
		sr: '85',
		name: 'Mrs. Rajkumari Yadav',
		mob: '9907286946'
	},
	{
		sr: '86',
		name: 'Shri Karan Kumar Baharani',
		mob: '9630399995'
	},
	{
		sr: '87',
		name: 'Shri Aditya Tiwari',
		mob: '9634312138'
	},
	{
		sr: '88',
		name: 'Miss Anmol Sharma',
		mob: '8871433101'
	},
	{
		sr: '89',
		name: 'Shri Shivendu Pandya',
		mob: '9406201655'
	},
	{
		sr: '90',
		name: 'Shri Pramod Ramteke',
		mob: '8225809264'
	},
	{
		sr: '91',
		name: 'Miss Neelima Singh Thakur',
		mob: '9630054999'
	},
	{
		sr: '92',
		name: 'Miss Ayushi Agrawal',
		mob: '7987562014'
	},
	{
		sr: '93',
		name: 'Shri Arpit Agrawal',
		mob: '8447022650'
	},
	{
		sr: '94',
		name: 'Shri Abhishek Chandra Gupta',
		mob: '9165554000'
	},
	{
		sr: '95',
		name: 'Shri Mayur Khandelwal',
		mob: '8770405025'
	}
];