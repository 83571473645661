import firebase from "firebase";
import "firebase/storage";

export default () => {
  if (firebase.apps.length === 0) {
    firebase.initializeApp({
      apiKey: "AIzaSyB-dzX3MLHUHoc0dGgDagjlW5JMv_KcXls",
      // authDomain: 'ag-project-2595.firebaseapp.com',
      projectId: "ag-website-e736e",
      storageBucket: "gs://test-f7a33.appspot.com",
    });
  }
  const db = firebase.firestore();
  // const storage = firebase.storage();
  return db;
};
