import React, { Component } from 'react';

const ags = [
  {
    src:
      'https://res.cloudinary.com/dcr2pfgxy/image/upload/v1561457103/ag-portal/former%20ags/ravindra_shrivastava.jpg',
    name: 'Shri Ravindra Shrivastava',
    from: '15/11/2000 ',
    to: '04/12/2003'
  },
  {
    src:
      'https://res.cloudinary.com/dcr2pfgxy/image/upload/v1561457103/ag-portal/former%20ags/ravish_chandra.jpg',
    name: 'Shri Ravish Chandra Agrawal',
    from: '22/12/2003',
    to: '31/08/2007'
  },
  {
    src:
      'https://res.cloudinary.com/dcr2pfgxy/image/upload/v1561457102/ag-portal/former%20ags/prashant_mishara.jpg',
    name: 'Shri Prashant Mishra',
    from: '03/09/2007',
    to: '30/11/2009'
  },
  {
    src:
      'https://res.cloudinary.com/dcr2pfgxy/image/upload/v1561457102/ag-portal/former%20ags/deoran_singh.jpg',
    name: 'Shri Deoraj Singh Surana',
    from: '15/12/2009',
    to: '21/06/2012'
  },
  {
    src:
      'https://res.cloudinary.com/dcr2pfgxy/image/upload/v1561457104/ag-portal/former%20ags/sanjay_k_agrwal.jpg',
    name: 'Shri Sanjay K. Agrawal',
    from: '25/06/2012',
    to: '11/09/2013'
  },
  {
    src:
      'https://res.cloudinary.com/dcr2pfgxy/image/upload/v1561457103/ag-portal/former%20ags/jugal_kishore.jpg',
    name: 'Shri Jugal Kishore Gilda',
    from: '23/01/2014',
    to: '01/01/2019'
  },
  {
    src:
      'https://res.cloudinary.com/dcr2pfgxy/image/upload/v1561457102/ag-portal/former%20ags/kanak_tiwari.jpg',
    name: 'Shri Kanak Tiwari',
    from: '02/01/2019',
    to: '31/05/2019'
  },
  {
    src:
      'https://res.cloudinary.com/dcr2pfgxy/image/upload/v1561457104/ag-portal/former%20ags/staish_verma.jpg',
    name: 'Shri Satish Chandra Verma',
    from: '1/06/2019',
    to: '26/12/2023'
  },
  //Added new AG
  //added this new former Ag
  {
    src:
      "https://i.ibb.co/gR93WGs/advocate-general-cg.png",
    name: 'Shri Praphulla Kumar Bharat',
    from: '12/01/2024',
    to: 'Present'
  }
];

export class FormerAGPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <section className='bg-white border-b '>
        <div className='container mx-auto flex flex-wrap pt-4 pb-12' id='myP'>
          <h1 className='w-full my-2 text-2xl font-bold leading-tight text-center text-gray-800'>
            Former AG's
          </h1>
          <div className='w-full mb-4'>
            <div className='h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t' />
          </div>
          <table className='text-left w-full'>
            <thead className='bg-black flex text-white w-full'>
              <tr className='flex w-full mb-4'>
                <th className='p-4 w-1/4'>Advocate General</th>
                <th className='p-4 w-1/4'>Name</th>
                <th className='p-4 w-1/4'>From</th>
                <th className='p-4 w-1/4'>To</th>
              </tr>
            </thead>
            {/* Remove the nasty inline CSS fixed height on production and replace it with a CSS class — this is just for demonstration purposes! */}
            <tbody className='flex flex-col items-center justify-between overflow-y-scroll w-full'>
              {ags.map((ag, i) => {
                return (
                  <tr
                    className='flex w-full mb-4 justify-center items-center'
                    key={i}
                  >
                    <td className='p-4 w-1/4'>
                      <img
                        src={ag.src}
                        alt={ag.name}
                        className='imageresponsive'
                      />
                    </td>
                    <td className='p-4 w-1/4'>{ag.name}</td>
                    <td className='p-4 w-1/4'>{ag.from}</td>
                    <td className='p-4 w-1/4'>{ag.to}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </section>
    );
  }
}

export default FormerAGPage;
