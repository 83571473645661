import React, { Component } from "react";
import { NotificationManager } from "react-notifications";

import getfirebase from "../../firebase.js";

const db = getfirebase();

class LawOfficersDashPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      designation: "",
      mobilenumber: "",
      whatsapp: "",
      officecontact: "",
    };
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    const { name, email, designation, mobilenumber, whatsapp, officecontact } = this.state;
    e.preventDefault();
    db.collection("lawofficers")
      .add({
        name,
        email,
        designation,
        mobilenumber,
        whatsapp,
        officecontact,
        seq: 0,
      })
      .then(() => {
        NotificationManager.success("Data Added Successfully.", "Success");
        this.setState({
          name: "",
          email: "",
          designation: "",
          mobilenumber: "",
          whatsapp: "",
          officecontact: "",
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    const { name, email, designation, mobilenumber, whatsapp, officecontact } = this.state;

    return (
      <React.Fragment>
        <form onSubmit={this.handleSubmit}>
          <div className="container px-8 py-8 mx-auto">
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                  Name of law officers
                </label>
                <input
                  name="name"
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-password"
                  type="text"
                  placeholder="Enter Name of officers"
                  onChange={this.handleChange}
                  value={name}
                  required
                />
              </div>

              <div className="w-full px-3">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                  Designation
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-password"
                  type="text"
                  placeholder="Enter Designation"
                  name="designation"
                  onChange={this.handleChange}
                  value={designation}
                  required
                />
              </div>

              <div className="w-full px-3">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                  Office Contact
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-password"
                  type="text"
                  placeholder="Enter Office contact"
                  name="officecontact"
                  onChange={this.handleChange}
                  value={officecontact}
                  required
                />
              </div>

              <div className="w-full px-3">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                  Mobile Number
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-password"
                  type="number"
                  placeholder="Enter Mobile Number"
                  name="mobilenumber"
                  onChange={this.handleChange}
                  value={mobilenumber}
                  required
                />
              </div>

              <div className="w-full px-3">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                  Whatsapp
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-password"
                  type="number"
                  placeholder="Enter whatsapp number"
                  name="whatsapp"
                  onChange={this.handleChange}
                  value={whatsapp}
                />
              </div>

              <div className="w-full px-3">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                  Email
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-password"
                  type="email"
                  placeholder="Enter email"
                  name="email"
                  onChange={this.handleChange}
                  value={email}
                  required
                />
              </div>

              <button className="hover:bg-blue-dark text-white font-bold py-2 px-4 rounded-full" style={{ background: "blue", textAlign: "center" }}>
                Submit
              </button>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

export default LawOfficersDashPage;
