/* eslint-disable no-unused-vars */
import React, { Component, useState } from "react";
import { NotificationManager } from "react-notifications";

import getDatabase from "../../firebase";
import AgProfilePage from "../AgProfilePage";

const db = getDatabase();

export class ViewLawOfficersData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    db.collection("lawofficers")
      .orderBy("seq", "asc")
      .get()
      .then((snapshot) => {
        const users = [];
        snapshot.forEach((doc) => {
          const { id } = doc;
          const data = doc.data();
          users.push({ ...data, id });
        });
        this.setState({ users: users });
      })
      .catch((error) => console.log(error));
  }

  deleteData = (id) => {
    db.collection("lawofficers")
      .doc(id)
      .delete()
      .then(() => {
        NotificationManager.success("Data Deleted", "Success");
        const myuser = this.state.users;
        const lawofficer = myuser.filter((s) => s.id !== id);
        this.setState({ users: lawofficer });
        window.location.reload();
      })
      .catch(function (error) {
        console.error("Error Removing Document: ", error);
      });
  };
  render() {
    const { users } = this.state;
    return (
      <section className="bg-white border-b ">
        <div className="container mx-auto flex flex-wrap pt-4 pb-12" id="myP">
          <h1 className="w-full my-2 text-2xl font-bold leading-tight text-center text-gray-800">Law Officers</h1>
          <div className="w-full mb-4">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t" />
          </div>
          <table className="text-center shadow-lg w-full">
            <thead className=" flex w-full bg-black text-white">
              <tr className="flex text-left w-full">
                <th style={{ width: 50, height: 150 }}>Sr.No.</th>
                <th style={{ flex: 3, height: 150 }}>Name Of Law Officer</th>
                <th style={{ flex: 3, height: 150 }}>Designation</th>
                <th style={{ flex: 1, height: 150 }}>Office Contact</th>
                <th style={{ flex: 1, height: 150 }}>Mobile Number</th>
                <th style={{ flex: 1, height: 150 }}>WhatsApp</th>
                <th style={{ flex: 1, height: 150 }}>Email</th>
                <th style={{ width: 80, height: 150 }}>Action</th>
                <th style={{ width: 50, height: 150 }}>Sequence</th>
              </tr>
            </thead>

            <tbody className="flex flex-col items-center justify-between overflow-y-scroll w-full">
              {users.map((ag, i) => {
                return (
                  <tr className="flex w-full text-left" key={i}>
                    <td style={{ width: 50, border: "1px solid black", overflow: "scroll" }}>{i + 1}</td>
                    <td style={{ flex: 3, border: "1px solid black", overflowY: "scroll" }}>
                      <NameChangeComponent ag={ag} />
                    </td>
                    <td style={{ flex: 3, border: "1px solid black", overflowY: "scroll" }}>{ag.designation}</td>
                    <td style={{ flex: 1, border: "1px solid black", overflowY: "scroll" }}>{ag.officecontact}</td>
                    <td style={{ flex: 1, border: "1px solid black", overflowY: "scroll" }}>{ag.mobilenumber}</td>
                    <td style={{ flex: 1, border: "1px solid black", overflowY: "scroll" }}>{ag.whatsapp}</td>
                    <td style={{ flex: 1, border: "1px solid black", overflowY: "scroll" }}>{ag.email}</td>
                    <td style={{ width: 80, border: "1px solid black", overflowY: "scroll" }}>
                      <button
                        className="hover:bg-blue-dark text-white rounded-full "
                        style={{ background: "blue", textAlign: "center", margin: 5 }}
                        onClick={() => this.deleteData(ag.id)}
                      >
                        delete
                      </button>
                    </td>
                    <td style={{ width: 50, height: 150, border: "1px solid black" }}>
                      <SequenceComponent ag={ag} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </section>
    );
  }
}

export default ViewLawOfficersData;

const SequenceComponent = ({ ag }) => {
  const [seq, setSEQ] = useState(ag.seq);
  return (
    <input
      type="number"
      value={seq}
      style={{ width: 140, height: 150 }}
      onChange={(e) => {
        const value = e.target.value;
        setSEQ(value);
        db.collection("lawofficers")
          .doc(ag.id)
          .update({ seq: parseInt(value) })
          .then((res) => console.log(res));
      }}
    />
  );
};

const NameChangeComponent = ({ ag }) => {
  const [name, changeName] = useState(ag.name);
  return (
    <input
      type="text"
      value={name}
      style={{ width: "100%", height: 150 }}
      onChange={(e) => {
        const value = e.target.value;
        changeName(value);
        db.collection("lawofficers")
          .doc(ag.id)
          .update({ name: value })
          .then((res) => console.log(res));
      }}
    />
  );
};
