import React, { Component } from 'react';
import { NotificationManager } from 'react-notifications';
import getfirebase from '../../firebase';

const db = getfirebase();

export class DashBoardMarkingStatusPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			casetitle: '',
			pdflink: '',
			categary: 'Case Marking Status',
			markingStatus: []
		};
	}

	componentDidMount() {
		const myWidget = window.cloudinary.createUploadWidget(
			{
				cloudName: process.env.NODE_ENV === 'development' ? 'msahu2595' : 'dcr2pfgxy',
				uploadPreset: process.env.NODE_ENV === 'development' ? 'e3rej1yz' : 'xobr2iw5'
			},
			(error, result) => {
				if (!error && result && result.event === 'success') {
					if (result.info.url) {
						NotificationManager.success('Uploaded Successfully.', 'Success');
						this.setState({ pdflink: result.info.url });
					} else {
						NotificationManager.error('Something Went Wrong.');
					}
				}
			}
		);

		document.getElementById('upload_widget').addEventListener(
			'click',
			function() {
				myWidget.open();
			},
			false
		);

		db.collection('marking_status').get().then((querySnapshot) => {
			let blankMarkingStatus = [];
			querySnapshot.forEach((doc) => {
				const mydocument = doc.data();
				mydocument.id = doc.id;
				blankMarkingStatus.push(mydocument);
			});
			this.setState({ markingStatus: blankMarkingStatus });
		});
	}

	handleSubmit = (e) => {
		e.preventDefault();
		const { casetitle, pdflink, categary } = this.state;
		if (casetitle === '') {
			NotificationManager.warning('Enter Case Title');
			return;
		}
		if (pdflink === '') {
			NotificationManager.warning('Please Upload File');
			return;
		}
		db
			.collection('marking_status')
			.add({ casetitle, pdflink, categary })
			.then((docRef) => {
				if (docRef.id) {
					NotificationManager.success('Submited Successfully', 'Success');
					const statuses = this.state.markingStatus;
					statuses.unshift({
						id: docRef.id,
						casetitle,
						pdflink,
						categary
					});
					this.setState({ markingStatus: statuses });
					this.setState({ casetitle: '', pdflink: '', categary: 'Case Marking Status' });
				} else {
					NotificationManager.error('Something Went Wrong.');
				}
			})
			.catch((error) => {
				console.error('Error Adding Document: ', error);
			});
	};

	handleChange = (e) => this.setState({ [e.target.name]: e.target.value });

	handleDelete = (id) => {
		db
			.collection('marking_status')
			.doc(id)
			.delete()
			.then(() => {
				NotificationManager.success('Document Successfully Deleted!', 'Success');
				const status = this.state.markingStatus;
				const myLatestStatus = status.filter((s) => s.id !== id);
				this.setState({ markingStatus: myLatestStatus });
			})
			.catch(function(error) {
				console.error('Error Removing Document: ', error);
			});
	};

	render() {
		return (
			<React.Fragment>
				<div className="bg-blue-800 p-2 shadow text-xl text-white">
					<h3 className="font-bold pl-2">MARKING STATUS</h3>
				</div>
				<form
					className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
					onSubmit={this.handleSubmit}
					name="markingstatusform"
				>
					<div className="mb-4">
						<label
							className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
							htmlFor="grid-first-name"
						>
							Case Title
						</label>
						<input
							className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
							id="caseTitle"
							type="text"
							placeholder="Title"
							name="casetitle"
							value={this.state.casetitle}
							onChange={this.handleChange}
						/>
					</div>
					<div className="mb-4">
						<label
							className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
							htmlFor="grid-last-name"
						>
							File Upload
						</label>
						<button type="button" id="upload_widget" className="cloudinary-button">
							Upload files
						</button>
					</div>
					<div className="mb-4">
						<label
							className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
							htmlFor="grid-state"
						>
							Categary
						</label>
						<div className="relative">
							<select
								className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
								id="categary"
								name="categary"
								value={this.state.categary}
								onChange={this.handleChange}
							>
								<option>Case Marking Status</option>
								<option>Fresh State Appeal</option>
							</select>
							<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
								<svg
									className="fill-current h-4 w-4"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
								>
									<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
								</svg>
							</div>
						</div>
					</div>
					<div className="mb-4">
						<button
							className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
							type="submit"
						>
							SUBMIT
						</button>
					</div>
				</form>
				<div className="bg-blue-800 p-2 shadow text-xl text-white">
					<h3 className="font-bold pl-2">MARKING STATUS LISTINGS</h3>
				</div>
				{this.state.markingStatus.map((status, i) => {
					return (
						<div key={i} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
							<div className="px-6 py-4">
								<div className="font-bold text-xl mb-2 capitalize">Case Title : {status.casetitle}</div>
								<p className="text-gray-700 text-base">Categary : {status.categary}</p>
							</div>
							<div className="px-6 py-4">
								<span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2">
									File Link :
									<a
										href={status.pdflink}
										style={{
											fontFamily: 'Arial',
											color: 'red',
											textDecoration: 'underline'
										}}
									>
										Click to See File.
									</a>
								</span>
							</div>
							<div className="px-6 py-4">
								<button
									name="deleteButton"
									onClick={() => this.handleDelete(status.id)}
									className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
								>
									Delete
								</button>
							</div>
						</div>
					);
				})}
			</React.Fragment>
		);
	}
}

export default DashBoardMarkingStatusPage;
