/* eslint-disable no-unused-vars */
import React from "react";
import { Route, Switch } from "react-router-dom";
import LandingLayout from "./Layouts/LandingLayout";
import LandingPage from "./pages/LandingPage";
import TenderPage from "./pages/TenderPage";
import NotificationPage from "./pages/NotificationPage";
import JudgementAndOrderPage from "./pages/JudgementAndOrderPage";
import ReportPage from "./pages/ReportPage";
import DirectoryPage from "./pages/DirectoryPage";
import AgProfilePage from "./pages/AgProfilePage";
import FormerAGPage from "./pages/FormerAGPage";
import EmpanelledAdvocatesPage from "./pages/EmpanelledAdvocatesPage";
import LawOfficersPage from "./pages/LawOfficersPage";
import RoleAndFunctionPage from "./pages/RoleAndFunctionPage";
import TermsAndConditionPage from "./pages/TermsAndCondition";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import ContactUsPage from "./pages/ContactUsPage";
import PhotoGalleryPage from "./pages/PhotoGalleryPage";
import CalenderPage from "./pages/CalenderPage";
import InformationForIOCPage from "./pages/InformationForIOCPage";
import MarkingStatusPage from "./pages/MarkingStatusPage";
import RTIPage from "./pages/RTIPage";
import DahsboardLayout from "./Layouts/DahsboardLayout";
import DashBoardMarkingStatusPage from "./pages/Dashboard/DashBoardMarkingStatus";
import DashBoardGalleryPage from "./pages/Dashboard/DashBoardGalleryPage";
import DashBoardTenderPage from "./pages/Dashboard/DashBoardTenderPage";
import DashBoardAnnouncementPage from "./pages/Dashboard/DashBoardAnnouncementPage";
import WebsitePolicyPage from "./pages/WebsitePolicyPage";
import AboutusPage from "./pages/AboutusPage";
import LoginPage from "./pages/LoginPage";
import LawOfficersDashPage from "./pages/Dashboard/LawOfficersDashPage";
import ViewLawOfficersData from "./pages/Dashboard/ViewLawOfficersData";
import AddAgProfile from "./pages/Dashboard/AddAgProfile";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};
const sliderDetails = [
  { title: "reports" },
  { title: "reports" },
  { title: "reports" },
  { title: "reports" },
  { title: "reports" },
  { title: "reports" },
];
const App = () => (
  <div>
    <Switch>
      <Route exact path="/" component={Landing} />
      <Route exact path="/tender" component={Tender} />
      <Route exact path="/notification" component={Notification} />
      <Route exact path="/order" component={JudgementAndOrder} />
      <Route exact path="/report" component={Report} />
      <Route exact path="/directory" component={Directory} />
      <Route exact path="/agprofile" component={AGProfile} />
      <Route exact path="/formerag" component={FormerAG} />
      <Route exact path="/emadvocate" component={EmpanelledAdvocates} />
      <Route exact path="/officers" component={LawOfficer} />
      <Route exact path="/role" component={RoleAndFunction} />
      <Route exact path="/terms" component={TermsAndConditions} />
      <Route exact path="/privacy" component={PrivacyPolicy} />
      <Route exact path="/contactus" component={ContactUs} />
      <Route exact path="/gallery" component={Gallery} />
      <Route exact path="/calendar" component={Calendar} />
      <Route exact path="/ioc" component={IformationForIOC} />
      <Route exact path="/markstatus" component={MarkingStatus} />
      <Route exact path="/rti" component={RTI} />

      <Route exact path="/dashboard" component={DashBoardMarkingStatus} />
      <Route exact path="/dashboardgallery" component={DashBoardGallery} />
      <Route exact path="/dashboardtender" component={DashBoardTender} />
      <Route
        exact
        path="/dashboardannouncement"
        component={DashBoardAnnouncement}
      />

      <Route exact path="/websitepolicy" component={WebsitePolicy} />
      <Route exact path="/aboutus" component={Aboutuspage} />
      <Route exact path="/login" component={Loginpage} />
      <Route exact path="/lawofficers" component={LawOfficers} />
      <Route exact path="/viewlawofficersdata" component={Viewlawofficerdata} />
      <Route exact path="/addagprofile" component={Addagprofile} />

      <Route component={NoComponent} />
    </Switch>
  </div>
);

export default App;

const DashBoardMarkingStatus = () => (
  <DahsboardLayout>
    <DashBoardMarkingStatusPage />
  </DahsboardLayout>
);

const Addagprofile = () => (
  <DahsboardLayout>
    <AddAgProfile />
  </DahsboardLayout>
);

const Viewlawofficerdata = () => (
  <DahsboardLayout>
    <ViewLawOfficersData />
  </DahsboardLayout>
);

const LawOfficers = () => (
  <DahsboardLayout>
    <LawOfficersDashPage />
  </DahsboardLayout>
);

const DashBoardTender = () => (
  <DahsboardLayout>
    <DashBoardTenderPage />
  </DahsboardLayout>
);

const DashBoardAnnouncement = () => (
  <DahsboardLayout>
    <DashBoardAnnouncementPage />
  </DahsboardLayout>
);

const DashBoardGallery = () => (
  <DahsboardLayout>
    <DashBoardGalleryPage />
  </DahsboardLayout>
);

const Landing = () => (
  <LandingLayout>
    <LandingPage />
  </LandingLayout>
);

const Tender = () => (
  <LandingLayout>
    <TenderPage />
  </LandingLayout>
);

const Notification = () => (
  <LandingLayout>
    <NotificationPage />
  </LandingLayout>
);

const JudgementAndOrder = () => (
  <LandingLayout>
    <JudgementAndOrderPage />
  </LandingLayout>
);

const Report = () => (
  <LandingLayout>
    <ReportPage />
  </LandingLayout>
);

const Directory = () => (
  <LandingLayout>
    <DirectoryPage />
  </LandingLayout>
);

const AGProfile = () => (
  <LandingLayout>
    <AgProfilePage />
  </LandingLayout>
);

const FormerAG = () => (
  <LandingLayout>
    <FormerAGPage />
  </LandingLayout>
);

const EmpanelledAdvocates = () => (
  <LandingLayout>
    <EmpanelledAdvocatesPage />
  </LandingLayout>
);

const LawOfficer = () => (
  <LandingLayout>
    <LawOfficersPage />
  </LandingLayout>
);

const RoleAndFunction = () => (
  <LandingLayout>
    <RoleAndFunctionPage />
  </LandingLayout>
);

const TermsAndConditions = () => (
  <LandingLayout>
    <TermsAndConditionPage />
  </LandingLayout>
);

const PrivacyPolicy = () => (
  <LandingLayout>
    <PrivacyPolicyPage />
  </LandingLayout>
);

const ContactUs = () => (
  <LandingLayout>
    <ContactUsPage />
  </LandingLayout>
);

const Gallery = () => (
  <LandingLayout>
    <PhotoGalleryPage />
  </LandingLayout>
);

const Calendar = () => (
  <LandingLayout>
    <CalenderPage />
  </LandingLayout>
);

const IformationForIOC = () => (
  <LandingLayout>
    <InformationForIOCPage />
  </LandingLayout>
);

const MarkingStatus = () => (
  <LandingLayout>
    <MarkingStatusPage />
  </LandingLayout>
);

const RTI = () => (
  <LandingLayout>
    <RTIPage />
  </LandingLayout>
);

const WebsitePolicy = () => (
  <LandingLayout>
    <WebsitePolicyPage />
  </LandingLayout>
);

const Aboutuspage = () => (
  <LandingLayout>
    <AboutusPage />
  </LandingLayout>
);

const Loginpage = () => <LoginPage />;

const NoComponent = () => <h1>Not found</h1>;
