import React, { Component } from "react";
import { NotificationManager } from "react-notifications";
import getfirebase from "../../firebase";

const db = getfirebase();

export class DashBoardAnnouncementPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event: "",
      date: "",
      validity: "",
      link:"",
      eventListings: [],
    };
  }

  componentDidMount() {
    db.collection("announcement_event")
      .get()
      .then((querySnapshot) => {
        let emptyTenderlist = [];
        querySnapshot.forEach((doc) => {
          const mydocument = doc.data();
          mydocument.id = doc.id;
          emptyTenderlist.push(mydocument);
        });
        this.setState({ eventListings: emptyTenderlist });
      });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { event, date, validity, link } = this.state;
    if (event === "") {
      NotificationManager.warning("Enter Event Name");
      return;
    }
    if (date === "") {
      NotificationManager.warning("Enter Date");
      return;
    }
    if (validity === "") {
      NotificationManager.warning("Enter Validity");
      return;
    }
    if (link === "") {
      NotificationManager.warning("Enter pdf link");
      return;
    }
    db.collection("announcement_event")
      .add({
        event,
        date,
        validity,
        link
      })
      .then((docRef) => {
        if (docRef.id) {
          NotificationManager.success("Submited Successfully", "Success");
          const events = this.state.eventListings;
          events.unshift({
            id: docRef.id,
            event,
            date,
            link,
            validity,
          });
          this.setState({ eventListings: events });
          this.setState({
            event: "",
            date: "",
            validity: "",
            link:""
          });
        } else {
          NotificationManager.error("Something Went Wrong.");
        }
      })
      .catch((error) => {
        console.error("Error Adding Document: ", error);
      });
  };

  handleChange = (e) => this.setState({ [e.target.name]: e.target.value });

  handleDelete = (id) => {
    db.collection("announcement_event")
      .doc(id)
      .delete()
      .then(() => {
        NotificationManager.success(
          "Document Successfully Deleted!",
          "Success"
        );
        const events = this.state.eventListings;
        const myLatestevents = events.filter((s) => s.id !== id);
        this.setState({ eventListings: myLatestevents });
      })
      .catch(function (error) {
        console.error("Error Removing Document: ", error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <div className="bg-blue-800 p-2 shadow text-xl text-white">
          <h3 className="font-bold pl-2">ANNOUNCEMENT</h3>
        </div>
        <form
          className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
          onSubmit={this.handleSubmit}
          name="markingstatusform"
        >
          <div className="mb-4">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-first-name"
            >
              Event
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              placeholder="Event Name"
              name="event"
              value={this.state.event}
              onChange={this.handleChange}
            />
          </div>
          <div className="mb-4">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-first-name"
            >
              Date
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="date"
              name="date"
              value={this.state.date}
              onChange={this.handleChange}
            />
          </div>
          <div className="mb-4">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-first-name"
            >
              validity
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="date"
              name="validity"
              value={this.state.validity}
              onChange={this.handleChange}
            />
          </div>

            <div className="mb-4">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-first-name"
            >
              Add pdf link
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="url"
              name="link"
              value={this.state.link}
              onChange={this.handleChange}
            />
          </div>
          <div className="mb-4">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
              type="submit"
            >
              SUBMIT
            </button>
          </div>
        </form>
        <div className="bg-blue-800 p-2 shadow text-xl text-white">
          <h3 className="font-bold pl-2">ANNOUNCEMENT LISTINGS</h3>
        </div>
        {this.state.eventListings.map((events, i) => {
          return (
            <div
              key={i}
              className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
            >
              <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2 capitalize">
                  Event : {events.event}
                </div>
                <p className="text-gray-700 text-base">Date : {events.Date}</p>
              </div>
              <div className="px-6 py-4">
                <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2">
                  Validity : {events.validity}
                </span>
              </div>

                 <div className="px-6 py-4">
                <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2">
                  Pdf link : {events.link}
                </span>
              </div>
              <div className="px-6 py-4">
                <button
                  name="deleteButton"
                  onClick={() => this.handleDelete(events.id)}
                  className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                >
                  Delete
                </button>
              </div>
            </div>
          );
        })}
      </React.Fragment>
    );
  }
}

export default DashBoardAnnouncementPage;
