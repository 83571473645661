import React from 'react';
import DashBoardNavBar from '../components/DashBoardNavBar';
import SideNavBar from '../components/SideNavBar';
import AuthChecker from '../pages/AuthChecker';

const DahsboardLayout = (props) => {
	return (
		<React.Fragment>
			<div className="bg-gray-900 font-sans leading-normal tracking-normal mt-12">
				<DashBoardNavBar />
				<div className="flex flex-col md:flex-row">
					<SideNavBar />
					<div className="main-content flex-1 bg-gray-100 mt-12 md:mt-2 pb-24 md:pb-5">{props.children}</div>
				</div>
			</div>
		</React.Fragment>
	);
};
export default AuthChecker(DahsboardLayout);
