/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import getfirebase from '../firebase';
const db = getfirebase();

export class TenderPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tenderListings: []
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		db.collection('tender_listings').get().then((querySnapshot) => {
			let emptyTenderlist = [];
			querySnapshot.forEach((doc) => {
				const mydocument = doc.data();
				mydocument.id = doc.id;
				emptyTenderlist.push(mydocument);
			});
			this.setState({ tenderListings: emptyTenderlist });
		});
	}

	render() {
		return (
			<section className="bg-white border-b ">
				<div className="container mx-auto flex flex-wrap pt-4 pb-12" id="myP">
					<h1 className="w-full my-2 text-2xl font-bold leading-tight text-center text-gray-800">Tender</h1>
					<div className="w-full mb-4">
						<div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t" />
					</div>
					<table className="text-left w-full">
						<thead className="bg-black flex text-white w-full">
							<tr className="flex w-full mb-4">
								<th className="p-4 w-1/4">Tender Number</th>
								<th className="p-4 w-1/4">Title</th>
								<th className="p-4 w-1/4">Date</th>
								<th className="p-4 w-1/4">View</th>
								<th className="p-4 w-1/4">Validity</th>
							</tr>
						</thead>
						{/* Remove the nasty inline CSS fixed height on production and replace it with a CSS class — this is just for demonstration purposes! */}
						<tbody
							className="flex flex-col items-center justify-between overflow-y-scroll w-full"
							style={{ height: '50vh', color: 'black' }}
						>
							{this.state.tenderListings.map((tender, i) => {
								return (
									<tr key={i} className="flex w-full mb-4">
										<td className="p-4 w-1/4">{tender.tenderNo}</td>
										<td className="p-4 w-1/4">{tender.tenderTitle}</td>
										<td className="p-4 w-1/4">{tender.tenderStartDate}</td>
										<td className="p-4 w-1/4">
											<a style={{ color: 'blue' }} target="_blank" href={tender.tenderDocLink}>
												View
											</a>
										</td>
										<td className="p-4 w-1/4">{tender.tenderEndDate}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			</section>
		);
	}
}

export default TenderPage;
